import {
  FARMER_FIELDS,
  FARMER_LIST,
  FARMER_LIVESTOCK,
  FARMER_REGISTER_CLEAN,
  FARMER_REGISTER_FAILED,
  FARMER_REGISTER_SUCCESS,
} from "../actions/types"

const INIT_STATE = {}

const farmerReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FARMER_REGISTER_SUCCESS:
      return {
        ...state,
        registerSucceed: true,
      }
    case FARMER_REGISTER_CLEAN:
      return {
        ...state,
        registerSucceed: null,
        registerFailed: null,
      }

    case FARMER_REGISTER_FAILED:
      return {
        ...state,
        registerFailed: true,
      }
    case FARMER_LIST:
      return {
        ...state,
        farmerList: action.payload.farmerList,
      }
    case FARMER_FIELDS:
      return {
        ...state,
        fields: action.payload.fields,
        fieldsForSelection: _reduceFieldsForSelection(action.payload.fields)
      }
    case FARMER_LIVESTOCK:
      return {
        ...state,
        livestocks: action.payload.livestock,
        livestocksForSelection: _reduceLivestockForSelection(action.payload.livestock),
      }
    default:
      return state
  }
}


const _reduceLivestockForSelection = (livestocks) => {
  let livestocksForSelection = []

  livestocks.forEach((obj) => {
    livestocksForSelection.push({
      value: obj.id,
      label: `${obj.category} | ${obj.productive_type}`,
    })
  })

  return livestocksForSelection
}


const _reduceFieldsForSelection = (fields) => {
  let fieldsForSelection = []

  fields.forEach((obj) => {
    fieldsForSelection.push({
      value: obj.field,
      label: `${obj.code} | ${obj.location} | ${parseFloat(obj.area).toFixed(2)}ha`,
    })
  })

  return fieldsForSelection
}


export default farmerReducer
