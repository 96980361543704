import { Col, Row } from "antd"
import React from "react"
import { connect } from "react-redux"
import AdviceObligationCancellationTable from "../components/AdviceObligationCancellationTable"
import PageHeader from "../components/custom/PageHeader"
import { ADVICE_CANCELLED_OBLIGATION_TEXT } from "../../common/texts"

class AdviceObligationCancellation extends React.Component {
  render() {
    return (
      <>
        <PageHeader infoDescription={ADVICE_CANCELLED_OBLIGATION_TEXT}>
          Ακυρωμένες αναθέσεις
        </PageHeader>
        <Row gutter={[20, 20]}>
          <Col span={24}>
            <AdviceObligationCancellationTable />
          </Col>
        </Row>
      </>
    )
  }
}

export default AdviceObligationCancellation
