import React from "react"
import TableWithLoader from "antd-table-loader"
import { Button, Dropdown, Skeleton, Table } from "antd"

import { connect } from "react-redux"

import { getAdviceObligations } from "../actions/adviceAction"
import AdiviceObligationModal from "./AdviceObligationModal"

import { DeleteOutlined, MoreOutlined } from "@ant-design/icons"
import AdiviceObligationCancellationReasonModal from "./AdviceObligationCancellationReasonMoodal"
import { v4 } from "uuid"

class AdviceObligationTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focusField: null,
      selectedAdviceObligation: null,
      selectedCancelObligation: null,
    }
  }

  componentDidMount() {
    this.props.getAdviceObligations()
  }

  onAdviceObligationClicked = (record) => {
    this.setState({
      selectedAdviceObligation: record,
    })
  }

  columns = [
    {
      title: (
        <>
          Ωφελούμενος
        </>
      ),
      align: "center",
      children: [
        {
          title: 'Α.Φ.Μ.',
          align: 'center',
          render: (record) => `${record.farmer?.tax_number}`,
          key: 'tax_number',
        },
        {
          title: 'Ονοματεπώνυμο',
          align: 'center',
          render: (record) => `${record.farmer?.first_name} ${record.farmer?.last_name}`,
          key: 'name',
        },
      ],
      key: "advice-obligation-table-farmer",
    },
    {
      title: "Αναθέτης",
      dataIndex: "assigner",
      key: "advice-obligation-table-assigner",
      align: "center",
      responsive: ["lg"],
      render: (assigner) => `${assigner.first_name} ${assigner.last_name}`,
    },
    {
      title: "Σύμβουλος",
      dataIndex: "advisor",
      key: "advice-obligation-table-advisor",
      align: "center",
      render: (advisor) => `${advisor.first_name} ${advisor.last_name}`,
      responsive: ["lg"],
    },
    {
      title: "Συμβουλή",
      dataIndex: "advice",
      render: (advice_pack) => `${advice_pack.code} ${advice_pack.title}`,
      key: "advice-obligation-table-advice",
      align: "center",
    },
  ]

  actions = {
    title: "",
    render: (record) => (
      <>
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                label: "Ακύρωση",
                key: 1,
                icon: <DeleteOutlined />,
              },
            ],
            onClick: (event) => {
              event.domEvent.stopPropagation()

              switch (event.key) {
                case "1":
                  this.setState({ selectedCancelObligation: record })
                  break
                default:
                  break
              }
            },
          }}
        >
          <Button
            type={"text"}
            onClick={(e) => e.stopPropagation()}
            icon={<MoreOutlined />}
          ></Button>
        </Dropdown>
      </>
    ),
    key: "advice-obligation-table-actions",
    align: "center",
  }

  render() {
    let columns = _.cloneDeep(this.columns)
    if (this.props.profile.role == "tenant_admin") {
      columns.push(this.actions)
    }
    return (
      <>
        {this.state.selectedAdviceObligation ? (
          <AdiviceObligationModal
            closeModal={() => this.setState({ selectedAdviceObligation: null })}
            adviceObligation={this.state.selectedAdviceObligation}
          />
        ) : (
          <></>
        )}

        {this.state.selectedCancelObligation ? (
          <AdiviceObligationCancellationReasonModal
            closeModal={() => this.setState({ selectedCancelObligation: null })}
            adviceObligation={this.state.selectedCancelObligation}
          />
        ) : (
          <></>
        )}

        <TableWithLoader
          className="table-global"
          size={"small"}
          bordered={true}
          pagination={true}
          loader={{
            rows: 4,
            component: <Skeleton active loading title={false} />,
          }}
          columns={columns}
          dataSource={this.props.adviceObligations}
          rowKey={(record) => Object.keys(record).length === 0 ? v4() : record.id}
          rowClassName={(record) =>
            record.field === this.state.focusField
              ? "table-selected-row"
              : "table-hover"
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => this.onAdviceObligationClicked(record),
            }
          }}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    adviceObligations: state.advice.adviceObligations,
    profile: state.tenantAuth.profile,
  }
}

export default connect(mapStateToProps, { getAdviceObligations })(
  AdviceObligationTable
)
