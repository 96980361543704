import React from "react"
import { Button, Card, Col, Divider, Empty, Modal, Row } from "antd"
import {
  UploadOutlined,
  DownloadOutlined,
  DeleteOutlined,
} from "@ant-design/icons"
import { primaryColor } from "../colors"

class MessageDisplayModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }


  renderMessageFiles = (adviceMessageFiles) => {
    if (!adviceMessageFiles) return <></>


    if (adviceMessageFiles.length === 0)
      return (
        <Empty
          style={{
            justifySelf: "center",
            marginTop: "3%",
          }}
          imageStyle={{
            color: primaryColor,
          }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={"Δεν υπάρχουν αρχεία"}
        />
      )

    return adviceMessageFiles.map((message_file) => {
      return (
        <Card type="inner" style={{ marginBottom: "8px" }}>
          <Button
            onClick={() => openInNewTab(message_file.url)}
            type={"text"}
          >
            <DownloadOutlined
              style={{ fontSize: "15px", color: primaryColor }}
            />
          </Button>
          <a href={message_file.url}>
            <span style={{ marginLeft: "8px" }}>
              {message_file.name}
            </span>
          </a>
          <span style={{ marginLeft: "16px", fontSize: "10px" }}>
            {message_file.kb} KB
          </span>
          <br />
        </Card>
      )
    })
  }

  render() {
    return (
      <Modal
        width={"70%"}
        footer={[
          <Button key="ok" type="primary" onClick={this.props.closeModal}>
            Πίσω
          </Button>,
        ]}
        open={true}
        onCancel={() => this.props.closeModal()}
        onOk={() => this.props.closeModal()}
      >
        <Divider>{this.props.subject}</Divider>
        <Row gutter={[20, 20]}>
          <Col span={16}>
            <Card style={{ height: '100%' }} title={"Μήνυμα"}>
              <p>{this.props.body}</p>
            </Card>
          </Col>

          <Col span={8}>
            <Card style={{ height: '100%' }} title={"Αρχεία"}>
              {this.renderMessageFiles(this.props.files)}
            </Card>
          </Col>
        </Row>
      </Modal>
    )
  }
}

export default MessageDisplayModal
