import React from "react";
import PageHeader from "../../components/custom/PageHeader";
import { getMessageHistory } from "../../actions/messageAction";
import { connect } from "react-redux";
import moment from "moment"
import { addAlpha, danger } from "../../../common/colors"
import { DeleteOutlined, ExclamationCircleOutlined, SolutionOutlined, TeamOutlined } from "@ant-design/icons"
import TableWithLoader from "antd-table-loader"
import { Popconfirm, Popover, Skeleton, Tag } from "antd";
import MessageDisplayModal from "../../../common/components/MessageDisplayModal";
import { MESSAGE_HISTORY_TEXT } from "../../../common/texts";
import { v4 } from "uuid";

class MessageHistoryScreen extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedMessage: null,
            selectedMessageIndex: null
        }
    }

    componentDidMount() {
        this.props.getMessageHistory()
    }

    onMessageSelected = (message, index) => {
        this.setState({
            selectedMessage: message,
            selectedMessageIndex: index,
        })
    }

    columns = [
        {
            title: "Θέμα",
            dataIndex: "subject",
            key: "message-history-subject",
            align: "center",
        },
        {

            title: <><TeamOutlined /> Παραλήπτες</>,
            dataIndex: "total_recipients",
            key: "message-history-total_recipients",
            align: "center",
            filterSearch: true,
            filterMode: "tree",
            sorter: (a, b) => (a.total_recipients > b.total_recipients ? 1 : -1),
        },
        {
            title: "Διαβάστηκαν",
            dataIndex: "read_count",
            key: "message-history-read_count",
            align: "center",
            filterSearch: true,
            filterMode: "tree",
            sorter: (a, b) => (a.read_count > b.read_count ? 1 : -1),
        },
        {
            title: <><SolutionOutlined /> Συμβουλές</>,
            key: "message-history-advices",
            align: "center",
            render: (record) => {

                if (record.advices?.length == 0) {
                    return <>--</>
                }
                return record.advices?.map((advice) => {
                    return <Tag>{advice}</Tag>
                })
            }

        },
        {
            title: "Ημηρομηνία αποστολής",
            key: "message-history-sent_at",
            defaultSortOrder: "descend",
            dataIndex: "sent_at",
            align: "center",
            sorter: (a, b) => (a.sent_at > b.sent_at ? 1 : -1),
            render: (sent_at) => moment(sent_at).format("D MMMM yyyy" + " - HH:mm"),
        },
        // {
        //   title: "",
        //   key: "message-history-delete",
        //   align: "center",
        //   render: (record) => (
        //     <span>
        //       <Popconfirm
        //         icon={<ExclamationCircleOutlined style={{ color: danger }} />}
        //         onConfirm={(e) => {
        //           e.stopPropagation()
        //           this.props.deleteMessage(record.session_id)
        //         }}
        //         title={<span style={{ color: danger }}>ΠΡΟΣΟΧΗ</span>}
        //         placement="left"
        //         description={`Είστε σίγουρος για τη διαγραφή της αποστολής με θέμα "${record.subject}";`}
        //         okButtonProps={{
        //           loading: this.state.isPrintOutConfirmLoading[record.session_id],
        //         }}
        //         open={this.state.isPopConfirmOpen[record.session_id]}
        //         onCancel={(e) => {
        //           e.stopPropagation()

        //           this.setState({
        //             isPopConfirmOpen: [],
        //           })
        //         }}
        //       >
        //         <Popover
        //           content={
        //             <span style={{ color: danger, fontSize: "17px" }}>
        //               Ακύρωση αποστολής
        //             </span>
        //           }
        //         >
        //           <DeleteOutlined
        //             onClick={(e) => {
        //               e.stopPropagation()
        //               this.setState({
        //                 isPopConfirmOpen: { [record.session_id]: true },
        //               })
        //             }}
        //             style={{ fontSize: "20px", color: danger }}
        //           />
        //         </Popover>
        //       </Popconfirm>
        //     </span>
        //   ),
        // },
    ]

    render() {
        return (
            <>
                <PageHeader infoDescription={MESSAGE_HISTORY_TEXT}>
                    Ιστορικό Μηνυμάτων
                </PageHeader>

                {this.state.selectedMessage ?
                    <MessageDisplayModal
                        message={this.state.selectedMessage}
                        body={this.state.selectedMessage.body}
                        files={this.state.selectedMessage.files}
                        subject={this.state.selectedMessage.subject}
                        closeModal={() =>
                            this.setState({ selectedMessage: null, selectedMessageIndex: null })
                        }
                    />
                    :
                    <></>}
                <TableWithLoader
                    loader={{
                        rows: 4,
                        component: <Skeleton active loading title={false} />,
                    }}
                    size={"small"}
                    bordered={true}
                    dataSource={this.props.messages}
                    columns={this.columns}
                    rowKey={(record) => Object.keys(record).length === 0 ? v4() : record.session_id}
                    pagination={{
                        pageSize: 10,
                        position: ["bottomCenter"],
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: (event) => this.onMessageSelected(record, rowIndex),
                        }
                    }}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        messages: state.message.messageHistoryList
    }
}

export default connect(mapStateToProps, { getMessageHistory })(MessageHistoryScreen)