import store from "../../store"
import { notification } from "antd"
import { cleanNotification } from "../actions/notificationAction"


export const notificationListener = () => {
  /**
   * This function listens for notification state change and opens a notification.
   */
  const state = store.getState()

  if (!state.notifications.notification) return

  notification.destroy()

  const notifcationConfig = {
    message: state.notifications.notification.title,
    description: state.notifications.notification.description,
    placement: "topRight",
    duration: state.notifications.notification.duration
      ? state.notifications.notification.duration
      : 5, // seconds
  }

  const status = state.notifications.notification.status

  switch (status) {
    case "success":
      notification.success(notifcationConfig)
      break
    case "info":
      notification.info(notifcationConfig)
      break
    case "error":
      notification.error(notifcationConfig)
      break
    default:
      notification.info(notifcationConfig)
      break
  }

  store.dispatch(cleanNotification())
}
