import React from "react"
import M2Header from "./M2Header"
import { getProfile } from "../actions/authAction"
import { connect } from "react-redux"
import { getAdvicePacks } from "../actions/adviceAction"

class PrivateComponent extends React.Component {
  componentDidMount() {
    this.props.getProfile()
    this.props.getAdvicePacks()
  }

  render() {
    return <M2Header>{this.props.children}</M2Header>
  }
}

export default connect(null, { getProfile, getAdvicePacks })(PrivateComponent)
