import { Col, Divider, Row } from "antd"
import React from "react"
import TenantUserTable from "../components/TenantUserTable"
import PageHeader from "../components/custom/PageHeader"
import { EMPLOYEE_TEXT } from "../../common/texts"

class TenantEmployees extends React.Component {
  render() {
    return (
      <>
        <PageHeader infoDescription={EMPLOYEE_TEXT}>Χρήστες</PageHeader>
        <Row>
          <Col span={24}>
            <TenantUserTable />
          </Col>
        </Row>
      </>
    )
  }
}

export default TenantEmployees
