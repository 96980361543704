import { Col, Row, Spin } from "antd"
import React from "react"
import { primaryColor } from "../colors"

class Loader extends React.Component {
  render() {
    return (
      <Row align={"middle"} justify={"center"} style={{ marginTop: "16px" }}>
        <Col span={8} />
        <Col
          span={8}
          style={
            this.props.absoluteCenter && {
              position: "absolute",
              top: "70%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }
          }
        >
          <Spin
            spinning={true}
            style={styles.absoluteCenter}
            tip={this.props.tip ? this.props.tip : "Παρακαλώ περιμένετε"}
            size="large"
          />
        </Col>
        <Col span={8} />
      </Row>
    )
  }
}

const styles = {
  absoluteCenter: {
    whiteSpace: "pre-line",
    fontSize: "25px",
    textAlign: "center",
    color: primaryColor,
  },
}

export default Loader
