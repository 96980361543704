import {
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Input,
  Modal,
  Row,
  Typography,
  message,
} from "antd"
import React from "react"
import {
  cancelAdviceObligation,
  adviceObligationCancellationClean,
} from "../actions/adviceAction"
import { connect } from "react-redux"

class AdiviceObligationCancellationReasonModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reason: "",
      loading: false,
    }
  }

  onCancelObligationPressed = () => {
    this.props.cancelAdviceObligation(
      this.props.adviceObligation.id,
      this.state.reason
    )
    this.setState({ loading: true })
  }

  componentDidUpdate() {
    if (this.props.adviceObligationCancellationFailed) {
      this.setState({ loading: false })
      this.props.adviceObligationCancellationClean()
      return
    }

    if (this.props.adviceObligationCancellationSucceed) {
      this.setState({ loading: false })
      message.success("Η ανάθεση ακυρώθηκε.")
      this.props.adviceObligationCancellationClean()
      this.props.closeModal()
    }
  }

  renderReasonForm = () => {
    return (
      <>
        <Input.TextArea
          onChange={(e) => {
            this.setState({ reason: e.target.value })
          }}
          className="inputs"
          style={{ height: "32vh" }}
          placeholder={"Ο αιτία που ακυρώνω τη συμβουλή είναι.."}
          disabled={false}
          maxLength={2500}
          showCount={true}
        />
      </>
    )
  }

  render() {
    return (
      <Modal
        width={"70%"}
        footer={[
          <Button key="ok" type="primary" onClick={this.props.closeModal}>
            Άκυρο
          </Button>,
        ]}
        open={true}
        onCancel={() => this.props.closeModal()}
        onOk={() => this.props.closeModal()}
      >
        <Divider>Ακύρωση ανάθεσης</Divider>
        <Row style={{ marginTop: "16px" }}>
          <Col span={24}>
            <b>Αιτία ακύρωσης</b>
          </Col>
        </Row>
        <Row style={{ marginTop: "16px" }}>
          <Col span={24}>{this.renderReasonForm()}</Col>
        </Row>

        <Button
          loading={this.state.loading}
          style={{ marginTop: "16px" }}
          onClick={this.onCancelObligationPressed}
        >
          Ακύρωση ανάθεσης συμβουλής
        </Button>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    adviceObligationCancellationSucceed:
      state.advice.adviceObligationCancellationSucceed,
    adviceObligationCancellationFailed:
      state.advice.adviceObligationCancellationFailed,
  }
}

export default connect(mapStateToProps, {
  cancelAdviceObligation,
  adviceObligationCancellationClean,
})(AdiviceObligationCancellationReasonModal)
