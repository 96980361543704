import React from "react"
import TableWithLoader from "antd-table-loader"
import { connect } from "react-redux"
import { Skeleton } from "antd"
import {
  getMessagesByTaxNumber,
  cleanMessageList,
} from "../actions/messageAction"
import { tableRowCellWrapper } from "../../common/utils/utils"
import MessageDisplayModal from "../../common/components/MessageDisplayModal"
import { v4 } from "uuid"

class MessageList extends React.Component {
  columns = [
    {
      title: "Θέμα",
      render: (record) => tableRowCellWrapper(record.subject, true, false),
      key: "message-list-subject",
      align: "left",
    },
    {
      title: "Μήνυμα",
      render: (record) => tableRowCellWrapper(record.body, false, false),
      key: "message-list-body",
      align: "left",
    },
    {
      title: "Κατάσταση συμβουλής",
      render: (record) => tableRowCellWrapper(record.status, false, false),
      key: "message-list-status",
      align: "left",
    },
  ]

  constructor(props) {
    super(props)

    this.state = {
      displayMessage: null,
    }
  }

  componentDidMount() {
    this.props.getMessagesByTaxNumber(this.props.taxNumber)
  }

  componentWillUnmount() {
    this.props.cleanMessageList()
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.taxNumber !== prevProps.taxNumber) {
      this.props.getMessagesByTaxNumber(this.props.taxNumber)
    }
  }

  onMessageClicked = (message) => {
    this.setState({
      displayMessage: {
        body: message.body,
        subject: message.subject,
        files: message.files
      },
    })
  }

  render() {
    return (
      <>
        {this.state.displayMessage ? (
          <MessageDisplayModal
            closeModal={() => this.setState({ displayMessage: null })}
            subject={this.state.displayMessage.subject}
            body={this.state.displayMessage.body}
            files={this.state.displayMessage.files}
          />
        ) : (
          <></>
        )}

        <TableWithLoader
          className="table-global"
          locale={{
            emptyText: "Δεν υπάρχουν διαθέσιμα μηνύματα.",
          }}
          size={"small"}
          bordered={true}
          pagination={true}
          loader={{
            rows: 4,
            component: <Skeleton active loading title={false} />,
          }}
          columns={this.columns}
          dataSource={this.props.messages}
          rowKey={(record) => Object.keys(record).length === 0 ? v4() : record.sent_at}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => this.onMessageClicked(record),
            }
          }}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.message.messageList,
  }
}

export default connect(mapStateToProps, {
  getMessagesByTaxNumber,
  cleanMessageList,
})(MessageList)
