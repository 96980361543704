const isDevelopment = process.env.NODE_ENV === "development"

export const API_URL = isDevelopment
  ? "http://localhost:8000/api/v1"
  : process.env.REACT_APP_API_URL

export const THROTLE_REQUEST = 0 // in milliseconds

export const DATE_FORMAT = "DD-MM-YYYY"

export const SYSTEM_USERNAME = "m2-advices-platform"

export const KEYS_TO_HIDE_HEDAER = ["smart-agriculture"]
export const KEYS_SYNFIELD_BELONGS = ["smart-agriculture"]

export const PLATFORM_NAMΕ = "Διαχείριση Γεωργικών Συμβουλών"

export const COMPANY_SITE = "https://foodstandard.gr/"
export const COMPANY_FULL_NAME = "FOODSTANDARD"
export const COMPANY_PRIMARY_COLOR = "#ba9765"

import TenantBackground from "../../public/tenant-background.jpg"
export const TENANT_LOGIN_BACKGROUND = TenantBackground

import FarmerBackground from "../../public/farmer-background.jpg"
export const FARMER_LOGIN_BACKGROUND = FarmerBackground

import LogoFile from "../../public/foodstandard.png"
export const LOGO_FILE = LogoFile
