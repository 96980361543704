import { COMPANY_PRIMARY_COLOR } from "./config"

export const primaryColor = COMPANY_PRIMARY_COLOR
export const secondaryColor = "#001529"
export const polygonFillColor = "#ADD8E6"
export const greyText = "#b0b3b8"
export const danger = "#FF0000"
export const synfieldColor = "#58b192"

export const addAlpha = (color, opacity) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255)
  return color + _opacity.toString(16).toUpperCase()
}
