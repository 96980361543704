import React from "react"
import RegisterEmployeeForm from "./RegisterEmployeeForm"
import { Button, Divider, Modal } from "antd"
import { connect } from "react-redux"
import { saveTenant } from "../actions/tenantAction"

class EditEmployeeModel extends React.Component {

    render() {
        return (
            <Modal
                width={"70%"}
                height={"90%"}
                footer={[
                    <Button key="ok" type="primary" onClick={this.props.closeModal}>
                        Πίσω
                    </Button>,
                ]}
                open={true}
                onCancel={() => this.props.closeModal()}
                onOk={() => this.props.closeModal()}
            >
                <Divider>Επεξεργασία χρήστη</Divider>
                <RegisterEmployeeForm
                    shouldResetFromAfterAction={false}
                    defaultValues={this.props.employee}
                    saveAction={this.props.saveTenant}
                    successMessage={`Ο χρήστης ενημερώθηκε.`}
                    saveButtonText={'Ενημέρωση χρήστη'}
                    onSuccessExtraAction={() => { }}
                />
            </Modal>
        )
    }
}

export default connect(null, { saveTenant })(EditEmployeeModel)