import {
    MESSAGE_LIST,
  } from "../actions/types"
  
  const INIT_STATE = {}
  
  const messageReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case MESSAGE_LIST:
        return {
          ...state,
          messageList: action.payload.messages,
        }
      default:
        return state
    }
  }
  
  export default messageReducer
  