import { Col, Image, Menu, Row } from "antd"
import { Content } from "antd/es/layout/layout"

import React from "react"

import { connect } from "react-redux"
import { onLogout } from "../actions/authAction"

import {
  LogoutOutlined,
  UsergroupDeleteOutlined,
  UserAddOutlined,
  UserOutlined,
  TeamOutlined,
  ClockCircleOutlined,
  QuestionCircleOutlined,
  SnippetsOutlined,
  SendOutlined,
  CommentOutlined,
  MessageOutlined
} from "@ant-design/icons"
import { NavLink } from "react-router-dom"
// import headercover from "../../../public/headercover.jpg"
import {
  lowerCaseAllWordsExceptFirstLetters,
  mapLocationToMenuKey,
} from "../../common/utils/utils"
import { primaryColor } from "../../common/colors"
import { COMPANY_FULL_NAME, COMPANY_SITE, LOGO_FILE, PLATFORM_NAMΕ } from "../../common/config"
import moment from "moment"
const { SubMenu } = Menu

class M2Header extends React.Component {
  constructor(props) {
    super(props)
  }

  onManualPressed = () => {
    console.log("To be implemented when we have user's manual.")
  }

  onLogoutPressed = () => {
    this.props.onLogout()
  }

  headerItems = () => {
    const logoutItem = {
      danger: true,
      key: "logout",
      label: "Αποσύνδεση",
      icon: <LogoutOutlined />,
      onClick: () => this.onLogoutPressed(),
    }

    const sessionsItem = {
      key: "sessions",
      label: <NavLink to="/sessions">Χρόνοι που αφιερώθηκαν</NavLink>,
      icon: <ClockCircleOutlined />,
    }

    const userItem = {
      key: "employees-menu",
      label: "Χρήστες",
      icon: <UserOutlined />,
      children: [
        {
          key: "employees",
          label: <NavLink to="/employees">Χρήστες</NavLink>,
          icon: <UserOutlined />,
        },
        {
          key: "registerEmployee",
          label: <NavLink to="/registerEmployee">Προσθήκη χρήστη</NavLink>,
          icon: <UserAddOutlined />,
        },
      ],
    }

    const obligationItem = {
      key: "obligations-menu",
      label: "Αναθέσεις",
      icon: <TeamOutlined />,
      children: [
        {
          key: "",
          label: <NavLink to="/">Αναθέσεις</NavLink>,
          icon: <TeamOutlined />,
        },
        {
          key: "cancellations",
          label: <NavLink to="/cancellations">Ακυρωμένες αναθέσεις</NavLink>,
          icon: <UsergroupDeleteOutlined />,
        },
      ],
    }

    const messageItem = {
      key: "messages",
      label: "Μηνύματα",
      icon: <MessageOutlined />,
      children: [
        {
          key: "messageSend",
          label: <NavLink to="/messageSend">Αποστολή μηνύματος</NavLink>,
          icon: <SendOutlined />,
        },
        {
          key: "messageHistory",
          label: <NavLink to="/messageHistory">Ιστορικό μηνυμάτων</NavLink>,
          icon: <CommentOutlined />,
        },
      ],
    }

    const helpItem = {
      key: "help",
      label: "Βοήθεια",
      icon: <QuestionCircleOutlined />,
      children: [
        {
          key: "manual",
          label: "Εγχειρίδιο Χρήσης",
          icon: <SnippetsOutlined />,
          onClick: this.onManualPressed(),
        },
      ],
    }

    if (this.props.profile.role == "tenant_employee") {
      return [obligationItem, sessionsItem, messageItem, helpItem, logoutItem]
    }

    return [obligationItem, sessionsItem, userItem, helpItem, logoutItem]
  }
  render() {
    return (
      <>
        <Row
          style={{
            top: 0,
            zIndex: 1000,
            display: 'flex'
          }}
        >
          <Col span={4} style={{ height: 'inherit', display: 'flex' }}>
            <Menu
              theme="dark"
              style={{
                borderRadius: 0,
                flexGrow: 1,
                minHeight: '100vh'
              }}
              selectedKeys={mapLocationToMenuKey()}
              mode="inline"
              defaultOpenKeys={["obligations-menu", "employees-menu", "messages-menu"]}
            >
              {/* <Image
                style={{
                  alignSelf: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  marginLeft: "10%",
                  //   ...LOGO_STYLES,
                }}
                width={190}
                src={LOGO_FILE}
                preview={false}
              /> */}
              <div style={{
                alignSelf: "center",
                marginBottom: 20,
                marginTop: 20,
                marginLeft: "10%",
                //   ...LOGO_STYLES,
              }}>

              </div>

              <Row
                style={{
                  marginLeft: "10%",
                }}
              >
                <Col span={2}>
                  <UserOutlined
                    style={{
                      marginTop: "45%",
                      fontSize: "22px",
                    }}
                  />
                </Col>
                <Col span={22}>
                  <Row>
                    <Col span={24}>
                      <p style={styles.userDetailsStyle}>
                        {lowerCaseAllWordsExceptFirstLetters(
                          `${this.props.profile.first_name} ${this.props.profile.last_name}`
                        )}
                      </p>
                    </Col>

                    <Col
                      span={24}
                      style={{
                        marginBottom: 20,
                        marginTop: "1.5%",
                        fontStyle: "italic",
                        marginLeft: "5%",
                      }}
                    >
                      <span>
                        {this.props.profile.role == "tenant_employee"
                          ? "Σύμβουλος"
                          : "Διαχειριστής"}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {this.headerItems().map((item) =>
                item.children ? (
                  <SubMenu key={item.key} icon={item.icon} title={item.label}>
                    {item.children.map((child) => (
                      <Menu.Item
                        icon={child.icon}
                        key={child.key}
                        onClick={child.onClick}
                        danger={item.danger}
                      >
                        {child.label}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    onClick={item.onClick}
                    danger={item.danger}
                  >
                    {item.label}
                  </Menu.Item>
                )
              )}
              <div style={{
                marginBottom: "8px",
                color: "white",
                position: 'absolute',
                bottom: 16, marginLeft: '10%',
              }}>
                {moment().year()}{" "}
                {/* <a target="_blank" rel="noreferrer" href={COMPANY_SITE}>
                  {COMPANY_FULL_NAME}
                </a> */}
              </div>
            </Menu>
          </Col>
          <Col span={20} style={{ display: 'flex' }}>
            <Content style={{ margin: "2%", flexGrow: 1 }}>{this.props.children}</Content>
          </Col>
        </Row>
      </>
    )
  }
}

const styles = {
  userDetailsStyle: {
    color: "white",
    marginLeft: "5%",
  },
  logoStyle: {
    color: primaryColor,
    marginLeft: "3%",
    marginTop: "1.5%",
  },
}

const mapStateToProps = (state) => {
  return {
    profile: state.tenantAuth.profile,
  }
}

export default connect(mapStateToProps, { onLogout })(M2Header)
