import React from "react"
import TableWithLoader from "antd-table-loader"
import { Skeleton } from "antd"

import { connect } from "react-redux"
import { getAdviceObligationCancellations } from "../actions/adviceAction"
import AdiviceObligationCancellationModal from "./AdviceObligationCancellationModal"
import { v4 } from "uuid"

class AdviceObligationTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focusField: null,
      selectedAdviceObligationCancellation: null,
    }
  }

  componentDidMount() {
    this.props.getAdviceObligationCancellations()
  }

  onAdviceObligationCancellationClicked = (record) => {
    this.setState({
      selectedAdviceObligationCancellation: record,
    })
  }

  columns = [
    {
      title: (
        <>
          Ωφελούμενος
        </>
      ),
      align: "center",
      children: [
        {
          title: 'Α.Φ.Μ.',
          align: 'center',
          render: (record) => `${record.farmer?.tax_number}`,
          key: 'tax_number',
        },
        {
          title: 'Ονοματεπώνυμο',
          align: 'center',
          render: (record) => `${record.farmer?.first_name} ${record.farmer?.last_name}`,
          key: 'name',
        },
      ],
      key: "advice-cancellation-obligation-table-farmer",
    },
    {
      title: "Αναθέτης",
      dataIndex: "assigner",
      key: "advice-cancellation-obligation-table-assigner",
      align: "center",
      responsive: ["lg"],
      render: (assigner) => `${assigner.first_name} ${assigner.last_name}`,
    },
    {
      title: "Σύμβουλος",
      dataIndex: "advisor",
      key: "advice-cancellation-obligation-table-advisor",
      align: "center",
      render: (advisor) => `${advisor.first_name} ${advisor.last_name}`,
      responsive: ["lg"],
    },
    {
      title: "Συμβουλή",
      dataIndex: "advice",
      render: (advice_pack) => `${advice_pack.code} ${advice_pack.title}`,
      key: "advice-cancellation-obligation-table-advice",
      align: "center",
    },
    {
      title: "Αιτία ακύρωσης",
      dataIndex: "reason",
      render: (reason) => `${reason}`,
      key: "advice-cancellation-obligation-table-reason",
      align: "center",
    },
  ]

  render() {
    return (
      <>
        {this.state.selectedAdviceObligationCancellation ? (
          <AdiviceObligationCancellationModal
            closeModal={() =>
              this.setState({ selectedAdviceObligationCancellation: null })
            }
            adviceObligationCancellation={
              this.state.selectedAdviceObligationCancellation
            }
          />
        ) : (
          <></>
        )}

        <TableWithLoader
          className="table-global"
          size={"small"}
          bordered={true}
          pagination={true}
          loader={{
            rows: 4,
            component: <Skeleton active loading title={false} />,
          }}
          columns={this.columns}
          dataSource={this.props.adviceObligationCancellations}
          rowKey={(record) => Object.keys(record).length === 0 ? v4() : record.id}
          rowClassName={(record) =>
            record.field === this.state.focusField
              ? "table-selected-row"
              : "table-hover"
          }
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) =>
                this.onAdviceObligationCancellationClicked(record),
            }
          }}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    adviceObligationCancellations: state.advice.adviceObligationCancellations,
  }
}

export default connect(mapStateToProps, { getAdviceObligationCancellations })(
  AdviceObligationTable
)
