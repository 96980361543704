import { DELIVERABLE_FORM, DELIVERABLE_LIST } from "../actions/types"
import { transformQuestionnaire } from "../utils/questionnaire"

const INIT_STATE = {}

const deliverableReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case DELIVERABLE_LIST:
      return {
        ...state,
        deliverables: action.payload.deliverables,
      }
    case DELIVERABLE_FORM:
      return {
        ...state,
        deliverableForm: _prepareForm(action.payload.deliverableForm),
      }
    default:
      return state
  }
}

const _prepareForm = (deliverableForm) => {
  if (deliverableForm.hasOwnProperty("questionnaire")) {
    deliverableForm["questionnaire"] = transformQuestionnaire(
      deliverableForm["questionnaire"]
    )
  }

  return deliverableForm
}

export default deliverableReducer