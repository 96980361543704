import { Button, Card, Col, Descriptions, Modal, Row, Typography } from "antd"
import React from "react"

class AdiviceObligationCancellationModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  renderAssigner = () => {
    return (
      <Card type="inner" style={{ marginTop: "24px" }} title="Αναθέτης">
        <Descriptions
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Ονοματεπώνυμο",
              span: 24,
              children: `${this.props.adviceObligationCancellation.assigner.first_name} ${this.props.adviceObligationCancellation.assigner.last_name}`,
            },
            {
              key: "2",
              label: "Ηλεκτρονική Διεύθυνση ",
              span: 24,
              children: `${this.props.adviceObligationCancellation.assigner.email}`,
            },
          ]}
        />
      </Card>
    )
  }

  renderAdvisor = () => {
    return (
      <Card type="inner" style={{ marginTop: "24px" }} title="Σύμβουλος">
        <Descriptions
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Ονοματεπώνυμο",
              span: 24,
              children: `${this.props.adviceObligationCancellation.advisor.first_name} ${this.props.adviceObligationCancellation.advisor.last_name}`,
            },
            {
              key: "2",
              label: "Ηλεκτρονική Διεύθυνση ",
              span: 24,
              children: `${this.props.adviceObligationCancellation.advisor.email}`,
            },
          ]}
        />
      </Card>
    )
  }

  renderFarmer = () => {
    return (
      <Card
        key={"Ωφελούμενος"}
        type="inner"
        style={{ marginTop: "24px", height: "100%" }}
        title="Ωφελούμενος"
      >
        <Descriptions
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Ονοματεπώνυμο",
              span: 24,
              children: `${this.props.adviceObligationCancellation.farmer.first_name} ${this.props.adviceObligationCancellation.farmer.last_name}`,
            },
            {
              key: "2",
              label: "Α.Φ.Μ.",
              span: 24,
              children: `${this.props.adviceObligationCancellation.farmer.tax_number}`,
            },
            {
              key: "3",
              label: "Ηλεκτρονική Διεύθυνση ",
              span: 24,
              children: `${this.props.adviceObligationCancellation.farmer.email}`,
            },
          ]}
        />
      </Card>
    )
  }

  renderAdvicePack = () => {
    return (
      <Card
        key={"Συμβουλή"}
        type="inner"
        style={{ marginTop: "24px", height: "100%" }}
        title="Συμβουλή"
      >
        <Descriptions
          layout="vertical"
          items={[
            {
              key: "1" ,
              label: "Τίτλος",
              span: 24,
              children: `${this.props.adviceObligationCancellation.advice.code} ${this.props.adviceObligationCancellation.advice.title}`,
            },
            {
              key: "2",
              label: "Περιγραφή",
              span: 24,
              children: `${this.props.adviceObligationCancellation.advice.description}`,
            },
          ]}
        />
      </Card>
    )
  }

  renderReason = () => {
    return (
      <Card
        key="Αιτία ακύρωσης"
        type="inner"
        style={{ marginTop: "24px", height: "100%" }}
        title="Αιτία ακύρωσης"
      >
        <Typography.Paragraph type="danger">
          {this.props.adviceObligationCancellation.reason}
        </Typography.Paragraph>
      </Card>
    )
  }

  render() {
    return (
      <Modal
        width={"70%"}
        footer={[
          <Button key="ok" type="primary" onClick={this.props.closeModal}>
            ΟΚ
          </Button>,
        ]}
        open={true}
        onCancel={() => this.props.closeModal()}
        onOk={() => this.props.closeModal()}
      >
        <Card
          title={"Ακυρωμένη ανάθεση συμβουλής"}
          style={{ marginTop: "24px" }}
        >
          <Row gutter={[10, 10]}>
            <Col span={24}>{this.renderReason()}</Col>
          </Row>

          <Row gutter={[10, 10]} style={{ marginTop: "16px" }}>
            <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
              <div style={{ flex: 1, marginBottom: "16px" }}>
                {this.renderAdvicePack()}
              </div>
            </Col>
            <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
              <div style={{ flex: 1, marginBottom: "16px" }}>
                {this.renderFarmer()}
              </div>
            </Col>
          </Row>

          <Row gutter={[10, 10]}>
            <Col span={12}>{this.renderAdvisor()}</Col>
            <Col span={12}>{this.renderAssigner()}</Col>
          </Row>
        </Card>
      </Modal>
    )
  }
}

export default AdiviceObligationCancellationModal
