import { api } from "../../common/api/api"
import { MESSAGE_LIST } from "./types"

export const getMessages = () => (dispatch) => {
  api
    .get(`farmer/message/list`)
    .then((response) => {
      dispatch({
        type: MESSAGE_LIST,
        payload: {
          messages: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const readMessage = (messageId) => (dispatch) => {
  api
    .patch(`farmer/message/${messageId}/read`)
    .then((response) => {
      dispatch(getMessages())
    })
    .catch((err) => {
      console.log(err)
    })
}
