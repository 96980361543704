import { showNotification } from "../../common/actions/notificationAction"
import { api } from "../../common/api/api"
import {
  TENANT_REGISTER_SUCCESS,
  CLEAN_TENANT_REGISTER_SUCCESS,
  TENANT_EMPLOYEE_LIST,
} from "./types"

export const registerTenant = (form) => (dispatch) => {
  api
    .post("tenant/employee/register", { ...form })
    .then((response) => {
      dispatch({
        type: TENANT_REGISTER_SUCCESS,
      })
    })
    .catch((err) => {
      let errorMessage = ""
      Object.entries(err.response.data).forEach(([key, value]) => {
        errorMessage = errorMessage + `Πρόβλημα στο πεδίο: ${key}` + "\n"
        value.forEach((msg) => {
          errorMessage = errorMessage + ` ${msg}` + "\n"
        })
      })

      dispatch(showNotification("Αποτυχία δημιουργίας", errorMessage, "error"))
    })
}

export const saveTenant = (form) => (dispatch) => {
  api
    .patch("tenant/employee/register", { ...form })
    .then((response) => {
      dispatch({
        type: TENANT_REGISTER_SUCCESS,
      })
    })
    .catch((err) => {
      let errorMessage = ""
      Object.entries(err.response.data).forEach(([key, value]) => {
        errorMessage = errorMessage + `Πρόβλημα στο πεδίο: ${key}` + "\n"
        value.forEach((msg) => {
          errorMessage = errorMessage + ` ${msg}` + "\n"
        })
      })

      dispatch(showNotification("Αποτυχία δημιουργίας", errorMessage, "error"))
    })
}

export const getTenantEmployees = () => (dispatch) => {
  api
    .get("tenant/employee")
    .then((response) => {
      dispatch({
        type: TENANT_EMPLOYEE_LIST,
        payload: {
          tenantEmployees: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const cleanRegisterTenant = () => (dispatch) => {
  dispatch({
    type: CLEAN_TENANT_REGISTER_SUCCESS,
  })
}
