const FARMER_PREFIX = "FARMER_"

// Authentication
export const LOGIN_SUCCESS = FARMER_PREFIX + "LOGIN_SUCCESS"
export const LOGIN_FAILURE = FARMER_PREFIX + "LOGIN_FAILURE"

export const LOAD_AUTH = FARMER_PREFIX + "LOAD_AUTH"

export const CLEAN_AUTH = FARMER_PREFIX + "CLEAN_AUTH"

// Message
export const MESSAGE_LIST = FARMER_PREFIX + "MESSAGE_LIST"

// Fields
export const FIELD_LIST = FARMER_PREFIX + "FIELD_LIST"
