import React from "react"
import { Button, Divider, Skeleton } from "antd"
import { RightOutlined } from "@ant-design/icons"
import TableWithLoader from "antd-table-loader"
import { connect } from "react-redux"
import CultivationModal from "../components/cultivation/CultivationModal"
import { getFields } from "../actions/fieldAction"

class FieldScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focusField: null,
      isCultivationModalOpen: [],
      openDrawerRecord: null,
    }
  }

  componentDidMount() {
    this.props.getFields()
  }

  columns = [
    {
      title: "Α/Α",
      dataIndex: "code",
      key: "code",
      align: "center",
      sorter: (a, b) => (a.code > b.code ? 1 : -1),
    },
    {
      title: "Τοποθεσία",
      dataIndex: "location",
      key: "location",
      align: "center",
    },
    {
      title: "Περίμετρος",
      dataIndex: "perimeter",
      render: (perimeter) => parseFloat(perimeter).toFixed(2) + "m",
      key: "perimeter",
      align: "center",
      responsive: ["lg"],
    },
    {
      title: "Ψηφ. Έκταση",
      dataIndex: "area",
      render: (area) => parseFloat(area).toFixed(2) + "ha",
      key: "locatareaion",
      align: "center",
      responsive: ["lg"],
    },
    {
      title: "Κοινότητα",
      dataIndex: "community",
      key: "community",
      align: "center",
      responsive: ["lg"],
    },
    {
      title: "Καλλιέργεια",
      key: "cultivation",
      align: "center",
      responsive: ["lg"],
      render: (record) => (
        <>
          <CultivationModal
            onCloseModal={() =>
              this.setState({
                isCultivationModalOpen: { [record.field]: false },
              })
            }
            cultivations={record.cultivations}
            isModalOpen={this.state.isCultivationModalOpen[record.field]}
          />
          <a
            onClick={() =>
              this.setState({
                isCultivationModalOpen: { [record.field]: true },
              })
            }
          >
            {record.cultivations.map((item) => item.cultivation + "")}
          </a>
        </>
      ),
    },
  ]

  render() {
    return (
      <>
      <Divider>Αγροτεμάχια</Divider>
        <TableWithLoader
          className="table-global"
          size={"small"}
          bordered={true}
          loader={{
            rows: 4,
            component: <Skeleton active loading title={false} />,
          }}
          columns={this.columns}
          dataSource={this.props.fields}
          rowKey={(record) => record.field}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    fields: state.farmerField.fields,
  }
}

export default connect(mapStateToProps, { getFields })(FieldScreen)
