import { Col, Row } from "antd"
import React from "react"
import { CREATE_EMPLOYEE_TEXT } from "../../common/texts"
import PageHeader from "../components/custom/PageHeader"
import RegisterEmployeeForm from "../components/RegisterEmployeeForm"
import { registerTenant } from "../actions/tenantAction"
import { connect } from "react-redux"

class RegisterEmployee extends React.Component {

  render() {
    return (
      <>
        <PageHeader infoDescription={CREATE_EMPLOYEE_TEXT}>
          Δημιουργία χειριστή φορέα
        </PageHeader>
        <Row gutter={[30, 30]} style={{ marginTop: "36px" }}>
          <Col span={6} />
          <Col span={12}>
            <RegisterEmployeeForm
              shouldResetFromAfterAction={true}
              saveAction={this.props.registerTenant}
              successMessage={`Ο χρήστης δημιουργήθηκε.`}
              saveButtonText={'Δημιουργία χρήστη'}
              onSuccessExtraAction={() => { }}
            />
          </Col>
          <Col span={6} />
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    registerSucceed: state.tenant.registerSucceed,
    advicePacks: state.advice.advicePacks,
  }
}
export default connect(mapStateToProps, {
  registerTenant,
})(RegisterEmployee)
