import { Button, Card, Cascader, Col, Form, Input, Row, Tabs, Upload, message } from "antd";
import React from "react";
import { connect } from "react-redux";
import {
    UploadOutlined, 
    SendOutlined
} from "@ant-design/icons"
import { cleanSendMessage, sendMessageByAdvice } from "../../actions/messageAction";

class SendByAdviceScreen extends React.Component {
    formRef = React.createRef()

    constructor(props) {
        super(props)

        this.state = {
            cascaderKey: 1,
            form: {
                advices: null,
                subject: null,
                body: null
            },
            files: [],
            loading: false
        }
    }

    componentDidUpdate() {
        if (this.props.messageSentSuccessfully) {
            this.props.cleanSendMessage()
            this.setState({
                loading: false,
                form: {
                    advices: null,
                    subject: null,
                    body: null
                },
                cascaderKey: this.state.cascaderKey + 1,
                files: []
            })
            this.formRef.current.resetFields()
            message.success("Το μήνυμα στάλθηκε!")
        }

        if (this.props.messageSentFailed) {
            this.setState({ loading: false })
            this.props.cleanSendMessage()
        }
    }

    onSendClicked = () => {
        this.setState({ loading: true })
        const form = this.state.form
        form['files'] = this.state.files
        this.props.sendMessageByAdvice(this.state.form)
    }

    updateFormItems = (formKey, value) => {
        this.setState((prevState) => ({
            form: {
                ...prevState.form,
                [formKey]: value
            },
        }))
    }


    adviceToRepresentation = () => {
        let advices = this.props.advices ? this.props.advices : []

        let advicesForSelection = []

        advices.forEach((obj) => {
            advicesForSelection.push({
                value: obj.code,
                label: obj.code,
            })
        })

        return advicesForSelection
    }

    renderMessageForm = () => {
        return (
            <>
                <Row style={{ marginTop: "1%" }} gutter={[20, 20]}>
                    <Col span={16} >
                        <Form ref={this.formRef} layout="vertical">
                            {/* Θέμα */}
                            <Form.Item
                                key={"name"}
                                name="name"
                                label={"Θέμα"}
                                rules={[
                                    {
                                        required: true,
                                        message: "Παρακαλώ εισάγετε θέμα.",
                                    },
                                ]}
                            >
                                <Input
                                    onChange={(e) => {
                                        this.updateFormItems("subject", e.target.value)
                                    }}
                                    className="inputs"
                                    style={{ height: "8vh" }}
                                    placeholder={"Θέμα"}
                                    disabled={false}
                                    maxLength={120}
                                    showCount={true}
                                />
                            </Form.Item>

                            {/* Μήνυμα */}
                            <Form.Item
                                key={"message"}
                                type="message"
                                name="message"
                                value
                                rules={[
                                    {
                                        required: true,
                                        message: "Το κέιμενο δεν μπορεί να είναι κενό",
                                    },
                                ]}
                            >
                                <Input.TextArea
                                    onChange={(e) => {
                                        this.updateFormItems("body", e.target.value)
                                    }}
                                    placeholder="Περιεχόμενο"
                                    className="inputs"
                                    style={{ height: "32vh", resize: "none" }}
                                    maxLength={10000}
                                    showCount={true}
                                />
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col span={8}>
                        <Card style={{ height: '50vh' }}>
                            <Upload
                                {...{
                                    multiple: true,
                                    onRemove: (file) => {
                                        const index = this.state.files.indexOf(file);
                                        const newFileList = this.state.files.slice();
                                        newFileList.splice(index, 1);
                                        this.setState({ files: newFileList })
                                    },
                                    beforeUpload: (file, fileList) => {
                                        this.setState({ files: [...this.state.files, ...fileList] })
                                        return false
                                    },
                                }}
                                fileList={this.state.files}
                            >
                                <Button
                                    style={{ marginTop: '8px', textAlign: 'center' }}
                                    icon={<UploadOutlined />}>
                                    Μεταφόρτωση αρχείων
                                </Button>
                            </Upload>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }


    render() {
        return (
            <>
                <Row gutter={[20, 20]}>
                    <Col span={6}>
                        <p>
                            Συμβουλές
                        </p>
                        <Cascader
                            key={this.state.cascaderKey}
                            style={{ width: "100%" }}
                            matchInputWidth
                            placeholder={"Σ.0.0"}
                            showSearch={true}
                            title="Συμβουλές"
                            placement={"topLeft"}
                            multiple={true}
                            allowClear={false}
                            options={this.adviceToRepresentation()}
                            onChange={(value) => {
                                if (!value) {
                                    this.updateFormItems("advices", null)
                                    return
                                }
                                this.updateFormItems("advices", value)
                            }}
                        />
                        <div style={{ marginTop: '24px' }}>
                            Το μήνυμα θα αποσταλεί σε όλους όσους έχετε ανατεθεί ως σύμβουλος για τις επιλεγμένες συμβουλές.
                        </div>

                        <Button
                            icon={<SendOutlined />}
                            style={{ marginTop: '25%', width: '100%' }}
                            loading={this.state.loading}
                            disabled={
                                !(this.state.form.advices && this.state.form.subject && this.state.form.body)
                            }
                            onClick={() => this.onSendClicked()}
                        >
                            Αποστολή
                        </Button>
                    </Col>

                    <Col span={18}>
                        {this.renderMessageForm()}
                    </Col>

                </Row>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        messageSentSuccessfully: state.message.sentSuccessfully,
        messageSentFailed: state.message.sentFailed,
        advices: state.tenantAuth.tenantProfile?.advices
    }
}

export default connect(mapStateToProps, { cleanSendMessage, sendMessageByAdvice })(SendByAdviceScreen)