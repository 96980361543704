import {
  Button,
  Col,
  DatePicker,
  Divider,
  InputNumber,
  Modal,
  Radio,
  Row,
  Skeleton,
} from "antd"
import TableWithLoader from "antd-table-loader"
import React from "react"
import { DATE_FORMAT } from "../../common/config"
import { connect } from "react-redux"
import {
  createHourWastedSession,
  getHourWastedSessions,
  cleanHourWastedSession,
} from "../actions/adviceAction"
import { ClockCircleOutlined, CalendarOutlined, AimOutlined, AuditOutlined } from "@ant-design/icons"
import dayjs from "dayjs"

class TimeWastedModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      hoursWasted: 1,
      sessionDate: null,
      sessionType: null,
      deliverableCode: null,

      loading: false,
    }
  }

  componentDidMount() {
    this.props.getHourWastedSessions(this.props.adviceObligationId)
  }

  componentDidUpdate() {
    if (this.props.saveFinished) {
      this.props.cleanHourWastedSession()
      this.setState({ loading: false })
    }
  }

  onSavePressed = () => {
    if (this.state.hoursWasted && this.state.sessionDate) {
      this.setState({ loading: true })
      this.props.createHourWastedSession(this.props.adviceObligationId, {
        hours_wasted: this.state.hoursWasted,
        session_datetime: this.state.sessionDate,
        session_type: this.state.sessionType,
        deliverable_code: this.state.deliverableCode
      })
    }
  }

  isButtonEnabled = () => {
    return this.state.hoursWasted && this.state.sessionDate && this.state.sessionType && this.state.deliverableCode
  }

  renderForm = () => {
    return (
      <>
        <Row gutter={[20, 20]} align={"middle"}>
          <Col span={24}>
            <h4>Προσθέστε ώρες</h4>
          </Col>

          <Col span={24}>
            <InputNumber
              style={{ width: "80%" }}
              prefix={<ClockCircleOutlined style={{ marginRight: "16px" }} />}
              min={1}
              defaultValue={1}
              onChange={(value) => this.setState({ hoursWasted: value })}
              max={this.props.workingHours ? this.props.workingHours : 8}
            />
          </Col>

          <Col span={24}>
            <DatePicker
              placeholder="Επιλέξτε ημερομηνία και ώρα"
              disabledHours={() => [0, 1, 2, 3, 4, 5, 21, 22, 23]}
              showTime={{
                minuteStep: 15,
                hideDisabledOptions: '',
                showSecond: false
              }}
              maxDate={dayjs()}
              style={{ width: "80%" }}
              showNow
              format={"DD/MM/YYYY - HH:mm"}
              onChange={(date) =>
                this.setState({ sessionDate: date })
              }
            />
          </Col>

          <Col span={24}>
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) => this.setState({ sessionType: e.target.value })}>
              <Radio.Button
                value="office">
                Εργασία
              </Radio.Button>
              <Radio.Button
                value="field">
                Επίσκεψη
              </Radio.Button>
            </Radio.Group>
          </Col>

          <Col span={24}>
            <Radio.Group
              buttonStyle="solid"
              onChange={(e) => this.setState({ deliverableCode: e.target.value })}>

              {this.props.deliverables?.map(deliverable => {
                return (
                  <Radio.Button
                    value={deliverable.code}>
                    {deliverable.title}
                  </Radio.Button>
                )
              })}
            </Radio.Group>
          </Col>

          <Col span={24}>
            <Button
              style={{ width: "80%" }}
              loading={this.state.loading}
              disabled={!this.isButtonEnabled()}
              onClick={() => this.onSavePressed()}
            >
              Προσθήκη
            </Button>
          </Col>
        </Row>
      </>
    )
  }

  sessionTableColumns = [
    {
      title: () => (
        <>
          <CalendarOutlined style={{ marginRight: "16px" }} /> Ημερομηνία
        </>
      ),
      dataIndex: "session_datetime",
      key: "date",
    },
    {
      title: () => (
        <>
          <AimOutlined style={{ marginRight: "16px" }} /> Τύπος συνεδρίας
        </>
      ),
      dataIndex: "session_type",
      key: "type",
    },
    {
      title: (
        <>
          <AuditOutlined style={{ marginRight: "16px" }} /> Παραδοτέο
        </>
      ),
      dataIndex: "deliverable_code",
      key: "deliverable_code",
    },
    {
      title: (
        <>
          <ClockCircleOutlined style={{ marginRight: "16px" }} /> Ώρες
        </>
      ),
      dataIndex: "hours_wasted",
      key: "hours",
    },
  ]

  renderSessions = () => {
    return (
      <TableWithLoader
        className="table-global"
        size={"small"}
        bordered={true}
        pagination={{ pageSize: 4 }}
        loader={{
          rows: 4,
          component: <Skeleton active loading title={false} />,
        }}
        columns={this.sessionTableColumns}
        dataSource={this.props.sessions}
        rowKey={(record) => record.field}
      />
    )
  }

  render() {
    return (
      <>
        <Modal
          width={"70%"}
          open={true}
          footer={[
            <Button
              key="ok"
              type="primary"
              loading={this.state.loading}
              onClick={this.props.closeModal}
            >
              Πίσω
            </Button>,
          ]}
          onCancel={() => this.props.closeModal()}
          onOk={() => this.props.closeModal()}
        >
          <Divider>Ώρες που αφιερώθηκαν για τη συμβουλή</Divider>

          <Row gutter={[30, 30]}>
            <Col span={10}>{this.renderForm()}</Col>
            <Col span={14}>{this.renderSessions()}</Col>
          </Row>
        </Modal>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    saveFinished: state.advice.hoursWastedFinsished,
    sessions: state.advice.obligationSessions,
    workingHours: state.tenantAuth.profile?.working_hours
  }
}

export default connect(mapStateToProps, {
  createHourWastedSession,
  getHourWastedSessions,
  cleanHourWastedSession,
})(TimeWastedModal)
