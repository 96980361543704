import { Button, Card, Col, Row } from "antd"
import React from "react"
import { connect } from "react-redux"

import {
  getDeliverables,
  getDeliverablePrintout,
} from "../actions/deliverableAction"
import moment from "moment"
import { DATE_FORMAT } from "../../common/config"
import EditDeliverable from "../screens/employee/EditDeliverable"
import { getFarmerFieldList, getFarmerLivestockList } from "../actions/farmerAction"

class DelverableList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedDeliverableCode: null,
    }
  }

  onEditPressed = (code) => {
    this.setState({ selectedDeliverableCode: code })
  }

  onPrintPressed = (code) => {
    this.props.getDeliverablePrintout(
      code,
      this.props.adviceCode,
      this.props.taxNumber
    )
  }

  componentDidMount() {
    this.props.getDeliverables()
    this.props.getFarmerFieldList(this.props.taxNumber)
    this.props.getFarmerLivestockList(this.props.taxNumber)
  }

  renderDeliverable = (deliverable) => {
    return (
      <Card
        key={deliverable.title}
        type="inner"
        style={{ marginTop: "24px", height: "auto" }}
        title={deliverable.title}
      >
        <p>
          <b>Περιγραφή:</b> {deliverable.description}
        </p>
        <p>
          <b>Υποβολή από:</b>{" "}
          {moment(deliverable.from_submission_date).format(DATE_FORMAT)}
        </p>
        <p>
          <b>Υποβολή μέχρι:</b>{" "}
          {moment(deliverable.to_submission_date).format(DATE_FORMAT)}
        </p>

        {this.renderActions(deliverable.code)}
      </Card>
    )
  }

  renderActions = (code) => {
    return (
      <>
        {this.state.selectedDeliverableCode ? (
          <EditDeliverable
            closeModal={() => this.setState({ selectedDeliverableCode: null })}
            deliverableCode={this.state.selectedDeliverableCode}
            adviceCode={this.props.adviceCode}
            taxNumber={this.props.taxNumber}
          />
        ) : (
          <></>
        )}

        <Row>
          <Col span={12}>
            <Button onClick={() => this.onEditPressed(code)}>
              Επεξεργασία
            </Button>
          </Col>
          <Col span={12}>
            <Button onClick={() => this.onPrintPressed(code)}>Εκτύπωση</Button>
          </Col>
        </Row>
      </>
    )
  }

  render() {
    if (!this.props.deliverables) {
      return <></>
    }

    return this.props.deliverables.map((deliverable) =>
      this.renderDeliverable(deliverable)
    )
  }
}

const mapStateToProps = (state) => {
  return {
    deliverables: state.deliverable.deliverables,
  }
}

export default connect(mapStateToProps, {
  getDeliverables,
  getDeliverablePrintout,
  getFarmerFieldList,
  getFarmerLivestockList
})(DelverableList)
