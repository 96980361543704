import { Button, Cascader, Col, Form, Input, InputNumber, Row, message } from "antd"
import React from "react"
import { ClockCircleOutlined } from "@ant-design/icons"
import { cleanRegisterTenant } from "../actions/tenantAction"
import { connect } from "react-redux"

class RegisterEmployeeForm extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      form: this.props.defaultValues ?
        {
          id: this.props.defaultValues.id
        } : {
          role: "tenant_employee",
          working_hours: 1
        },

      loading: false
    }
  }

  onSavePressed = () => {
    this.setState({ loading: true })
    this.props.saveAction(this.state.form)
  }

  updateFormItems = (formKey, value) => {
    this.setState((prevState) => ({
      form: {
        ...prevState.form,
        [formKey]: value,
      },
    }))
  }

  componentDidUpdate() {
    if (this.props.actionSucceed) {
      if (this.props.shouldResetFromAfterAction) {
        this.formRef.current.resetFields()
      }
      message.success(this.props.successMessage)
      this.setState({ form: {}, loading: false })
      this.props.cleanRegisterTenant()
      this.props.onSuccessExtraAction()
    }
  }

  options = [
    {
      value: "tenant_employee",
      label: "Χειριστής φορέα",
    },
    {
      value: "tenant_admin",
      label: "Διαχειριστής φορέα",
    },
  ]

  renderForm = () => {
    return (
      <Form ref={this.formRef} layout="vertical">
        {/* tax_number */}
        <Form.Item
          key={"tax_number"}
          name="tax_number"
          label={"Α.Φ.Μ"}
          rules={[
            {
              required: true,
              message: "Παρακαλώ εισάγετε το Α.Φ.Μ.",
            },
          ]}
        >
          <Input
            defaultValue={this.props.defaultValues?.tax_number}
            onChange={(e) => {
              this.updateFormItems("tax_number", e.target.value)
            }}
            className="inputs"
            style={{ height: "6vh" }}
            placeholder={"000000000"}
            disabled={false}
            maxLength={9}
            count={{
              max: 9,
              min: 9,
            }}
            showCount={true}
          />
        </Form.Item>
        {/* email */}
        <Form.Item
          key={"email"}
          name="email"
          label={"Email"}
          rules={[
            {
              type: "email",
              required: true,
              message: "Παρακαλώ εισάγετε email.",
            },
          ]}
        >
          <Input
            defaultValue={this.props.defaultValues?.email}
            onChange={(e) => {
              this.updateFormItems("email", e.target.value)
            }}
            className="inputs"
            style={{ height: "6vh" }}
            disabled={false}
          />
        </Form.Item>
        {/* username */}
        <Form.Item
          key={"username"}
          name="username"
          label={"Όνομα χρήστη"}
          rules={[
            {
              required: true,
              message: "Παρακαλώ εισάγετε όνομα χρήστη.",
            },
          ]}
        >
          <Input
            defaultValue={this.props.defaultValues?.username}
            onChange={(e) => {
              this.updateFormItems("username", e.target.value)
            }}
            className="inputs"
            style={{ height: "6vh" }}
            disabled={false}
          />
        </Form.Item>
        {/* first_name */}
        <Form.Item
          key={"first_name"}
          name="first_name"
          label={"Όνομα"}
          rules={[
            {
              required: true,
              message: "Παρακαλώ εισάγετε το όνομα",
            },
          ]}
        >
          <Input
            defaultValue={this.props.defaultValues?.first_name}
            onChange={(e) => {
              this.updateFormItems("first_name", e.target.value)
            }}
            className="inputs"
            style={{ height: "6vh" }}
            placeholder={"Όνομα"}
            disabled={false}
            maxLength={120}
            showCount={true}
          />
        </Form.Item>
        {/* last_name */}
        <Form.Item
          key={"last_name"}
          name="last_name"
          label={"Επώνυμο"}
          rules={[
            {
              required: true,
              message: "Παρακαλώ εισάγετε το επώνυμο",
            },
          ]}
        >
          <Input
            defaultValue={this.props.defaultValues?.last_name}
            onChange={(e) => {
              this.updateFormItems("last_name", e.target.value)
            }}
            className="inputs"
            style={{ height: "6vh" }}
            placeholder={"Επώνυμο"}
            disabled={false}
            maxLength={120}
            showCount={true}
          />
        </Form.Item>
        {/* father_name */}
        <Form.Item
          key={"father_name"}
          name="father_name"
          label={"Πατρώνυμο"}
          rules={[
            {
              required: true,
              message: "Παρακαλώ εισάγετε το πατρώνυμο",
            },
          ]}
        >
          <Input
            defaultValue={this.props.defaultValues?.father_name}
            onChange={(e) => {
              this.updateFormItems("father_name", e.target.value)
            }}
            className="inputs"
            placeholder="Πατρώνυμο"
            style={{ height: "6vh" }}
            disabled={false}
            maxLength={120}
            showCount={true}
          />
        </Form.Item>

        {/* mobile_phone_number */}
        <Form.Item
          key={"mobile_phone_number"}
          name="mobile_phone_number"
          label={"Τηλέφωνο"}
          rules={[
            {
              required: true,
              message: "Παρακαλώ εισάγετε το τηλέφωνο",
            },
          ]}
        >
          <Input
            defaultValue={this.props.defaultValues?.mobile_phone_number}
            onChange={(e) => {
              this.updateFormItems("mobile_phone_number", e.target.value)
            }}
            className="inputs"
            style={{ height: "6vh" }}
            placeholder={"+3069χχχχχχχχ"}
            disabled={false}
            maxLength={120}
            showCount={true}
          />
        </Form.Item>

        {/* identity_number */}
        <Form.Item
          key={"identity_number"}
          name="identity_number"
          label={"Αριθμός Ταυτότητας"}
          rules={[
            {
              required: false,
              message: "Παρακαλώ εισάγετε αριθμό ταυτότητας",
            },
          ]}
        >
          <Input
            defaultValue={this.props.defaultValues?.identity_number}
            onChange={(e) => {
              this.updateFormItems("identity_number", e.target.value)
            }}
            className="inputs"
            style={{ height: "6vh" }}
            placeholder={"AA 000000"}
            disabled={false}
            maxLength={120}
            showCount={true}
          />

          <Row gutter={[20, 20]}>
            <Col span={12}>
              <p
                style={{
                  marginTop: "16px",
                }}
              >
                Ρόλος νέου χρήστη
              </p>
              <Cascader
                title="Ρόλος νέου χρήστη"
                allowClear={false}
                defaultValue={this.props.defaultValues?.role ? this.props.defaultValues?.role : "tenant_employee"}
                options={this.options}
                onChange={(value) => this.updateFormItems("role", value[0])}
              />
            </Col>
            <Col span={12}>
              <p
                style={{
                  marginTop: "16px",
                }}
              >
                Ώρες εργασίας
              </p>
              <InputNumber
                style={{ width: "100%" }}
                prefix={<ClockCircleOutlined style={{ marginRight: "16px" }} />}
                min={1}
                defaultValue={this.props.defaultValues?.working_hours ? this.props.defaultValues?.working_hours : 1}
                onChange={(value) => this.updateFormItems("working_hours", value)}
                max={8}
              />
            </Col>
          </Row>


          {this.state.form.role == "tenant_employee" ? (
            <>
              <p
                style={{
                  marginTop: "16px",
                }}
              >
                Ανάθεση πακέτων συμβουλών που μπορεί διαχειριστει ο νέος χρήστης
              </p>
              <Cascader
                title="Ανάθεση πακέτων συμβουλών που μπορεί διαχειριστει ο νέος χρήστης ."
                placement={"topLeft"}
                multiple
                defaultValue={this.props.defaultValues?.advice_packs.map(advice_pack => [advice_pack.code])}
                allowClear={false}
                options={this.props.advicePacks}
                onChange={(value) => {
                  const advices = value.map((val) => {
                    return val[0]
                  })
                  this.updateFormItems("advice_packs", advices)
                }}
              />
            </>
          ) : (
            <></>
          )}

          <Button
            loading={this.state.loading}
            onClick={this.onSavePressed}
            htmlType="submit"
            style={{
              marginTop: "32px",
            }}
          >
            {this.props.saveButtonText}
          </Button>
        </Form.Item>
      </Form>
    )
  }

  render() {
    return (
      <>
        {this.renderForm()}
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    actionSucceed: state.tenant.registerSucceed,
    advicePacks: state.advice.advicePacks,
  }
}
export default connect(mapStateToProps, {
  cleanRegisterTenant,
})(RegisterEmployeeForm)
