import { LOGOUT } from "../../common/actions/types"
import {
  CLEAN_AUTH,
  LOAD_AUTH,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
} from "../actions/types"

const INIT_STATE = {}

const authReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        isLoggedIn: true,
        error: null,
      }
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoggedIn: false,
        error: action.payload.error,
        token: null,
      }
    case LOGOUT:
      return { ...state, token: null, isLoggedIn: false, error: null }
    case LOAD_AUTH:
      return {
        ...state,
        isLoggedIn: action.payload.token ? true : false,
        error: null,
        token: action.payload.token,
        profile: action.payload.profile,
      }
    case CLEAN_AUTH:
      return {
        ...state,
        error: null,
      }
    default:
      return state
  }
}

export default authReducer
