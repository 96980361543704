import {
    FIELD_LIST,
  } from "../actions/types"
  
  const INIT_STATE = {}
  
  const fieldReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
      case FIELD_LIST:
        return {
          ...state,
          fields: action.payload.fields,
        }
      default:
        return state
    }
  }
  
  export default fieldReducer
  