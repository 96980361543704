import { showNotification } from "../../common/actions/notificationAction"
import { api } from "../../common/api/api"
import {
  ADVICE_OBLIGATION_LIST,
  ADVICE_PACK_LIST,
  ADVICE_OBLIGATION_CREATE_SUCCEED,
  ADVICE_OBLIGATION_CREATE_FAILED,
  ADVICE_OBLIGATION_CREATE_CLEAN,
  ADVICE_OBLIGATION_OBJECT,
  CLEAN_ADVICE_OBLIGATION_OBJECT,
  TIME_WASTED_FINISHED,
  TIME_WASTED_SESSIONS,
  CLEAN_TIME_WASTED_FINISHED,
  ALL_TIME_WASTED_SESSIONS,
  EXPORT_SUCCESS,
  CLEAN_EXPORT_SUCCESS,
  ADVICE_OBLIGATION_CANCELLATION_LIST,
  ADVICE_OBLIGATION_CANCELLATION_SUCCEED,
  ADVICE_OBLIGATION_CANCELLATION_FAILED,
  ADVICE_OBLIGATION_CANCELLATION_CLEAN,
} from "./types"

export const getAdviceObligations = () => (dispatch) => {
  api
    .get("tenant/advice/obligation")
    .then((response) => {
      dispatch({
        type: ADVICE_OBLIGATION_LIST,
        payload: {
          adviceObligations: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getAdviceObligationCancellations = () => (dispatch) => {
  api
    .get("tenant/advice/obligation/cancellation")
    .then((response) => {
      dispatch({
        type: ADVICE_OBLIGATION_CANCELLATION_LIST,
        payload: {
          adviceObligationCancellations: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const cancelAdviceObligation = (id, reason) => (dispatch) => {
  api
    .post(`tenant/advice/obligation/${id}/cancellation`, { reason: reason })
    .then((response) => {
      dispatch({
        type: ADVICE_OBLIGATION_CANCELLATION_SUCCEED,
      })
      dispatch(getAdviceObligationCancellations())
      dispatch(getAdviceObligations())
    })
    .catch((err) => {
      dispatch({
        type: ADVICE_OBLIGATION_CANCELLATION_FAILED,
      })
      if (err.response.data.detail) {
        dispatch(
          showNotification(
            "Αποτυχία δημιουργίας",
            err.response.data.detail,
            "error"
          )
        )
        return
      }

      let errorMessage = ""
      Object.entries(err.response.data).forEach(([key, value]) => {
        errorMessage = errorMessage + `Πρόβλημα στο πεδίο: ${key}` + "\n"
        value.forEach((msg) => {
          errorMessage = errorMessage + ` ${msg}` + "\n"
        })
      })

      dispatch(showNotification("Αποτυχία δημιουργίας", errorMessage, "error"))
    })
}

export const adviceObligationCancellationClean = () => (dispatch) => {
  dispatch({
    type: ADVICE_OBLIGATION_CANCELLATION_CLEAN,
  })
}

export const createAdviceObligation = (obligationForm) => (dispatch) => {
  api
    .post("tenant/advice/obligation", { ...obligationForm })
    .then((response) => {
      console.log(response)
      dispatch({
        type: ADVICE_OBLIGATION_CREATE_SUCCEED,
      })

      dispatch(getAdviceObligations())
    })
    .catch((err) => {
      dispatch({
        type: ADVICE_OBLIGATION_CREATE_FAILED,
      })
      if (err.response.data.detail) {
        dispatch(
          showNotification(
            "Αποτυχία δημιουργίας",
            err.response.data.detail,
            "error"
          )
        )
        return
      }

      let errorMessage = ""
      Object.entries(err.response.data).forEach(([key, value]) => {
        errorMessage = errorMessage + `Πρόβλημα στο πεδίο: ${key}` + "\n"
        value.forEach((msg) => {
          errorMessage = errorMessage + ` ${msg}` + "\n"
        })
      })

      dispatch(showNotification("Αποτυχία δημιουργίας", errorMessage, "error"))
    })
}

export const createAdviceObligationClean = () => (dispatch) => {
  dispatch({
    type: ADVICE_OBLIGATION_CREATE_CLEAN,
  })
}

export const getAdvicePacks = () => (dispatch) => {
  api
    .get("advice-pack")
    .then((response) => {
      dispatch({
        type: ADVICE_PACK_LIST,
        payload: {
          advicePacks: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getAdviceObligationById = (id) => (dispatch) => {
  api
    .get(`tenant/advice/obligation/${id}`)
    .then((response) => {
      dispatch({
        type: ADVICE_OBLIGATION_OBJECT,
        payload: {
          adviceObligationObject: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const cleanAdviceObligation = () => (dispatch) => {
  dispatch({
    type: CLEAN_ADVICE_OBLIGATION_OBJECT,
  })
}

export const createHourWastedSession = (id, form) => (dispatch) => {
  api
    .post(`tenant/advice/obligation/${id}/session`, form)
    .then((response) => {
      dispatch({
        type: TIME_WASTED_FINISHED,
      })
      dispatch(getHourWastedSessions(id))
    })
    .catch((err) => {
      dispatch({
        type: TIME_WASTED_FINISHED,
      })
      dispatch(showNotification("Αποτυχία δημιουργίας", err.data.detail, "error"))
    })
}

export const cleanHourWastedSession = () => (dispatch) => {
  dispatch({
    type: CLEAN_TIME_WASTED_FINISHED,
  })
}

export const getHourWastedSessions = (id) => (dispatch) => {
  api
    .get(`tenant/advice/obligation/${id}/session`)
    .then((response) => {
      dispatch({
        type: TIME_WASTED_SESSIONS,
        payload: {
          sessions: response.data,
        },
      })
    })
    .catch((err) => {
      dispatch(showNotification("Αποτυχία δημιουργίας", "", "error"))
    })
}

export const getSessions = () => (dispatch) => {
  api
    .get(`tenant/advice/session`)
    .then((response) => {
      dispatch({
        type: ALL_TIME_WASTED_SESSIONS,
        payload: {
          sessions: response.data,
        },
      })
    })
    .catch((err) => {
      dispatch(showNotification("Αποτυχία δημιουργίας", "", "error"))
    })
}

export const exportSessions = () => (dispatch) => {
  api
    .get("tenant/advice/session/export", {
      responseType: "arraybuffer",
    })
    .then((response) => {
      saveAs(
        new Blob([response.data], { type: "application/ms-excel" }),
        `ΩΡΕΣ ΠΟΥ ΑΦΙΕΡΩΘΗΚΑΝ.xls`
      )
      dispatch({
        type: EXPORT_SUCCESS,
      })
      dispatch(
        showNotification("Επιτυχία", "Το αρχείο αποθηκέυτηκε.", "success")
      )
    })
    .catch((error) => {
      dispatch({
        type: CLEAN_EXPORT_SUCCESS,
      })
      dispatch(
        showNotification(
          "Αποτυχία",
          "Πρόβλημα στην δημιουργία του αρχείου.",
          "error"
        )
      )
    })
}

export const cleanExport = () => (dispatch) => {
  dispatch({
    type: CLEAN_EXPORT_SUCCESS,
  })
}
