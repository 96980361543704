import React from "react"
import { Popover, Skeleton } from "antd"
import { SYSTEM_USERNAME } from "../config"

import _ from "lodash"

export const convertToLatLngObj = (coord) => {
  return { lat: coord[1], lng: coord[0] }
}

export const getBounds = (paths) => {
  var bounds = new google.maps.LatLngBounds()
  var path
  for (var i = 0; i < paths.length; i++) {
    path = paths[i]
    for (var ii = 0; ii < path.length; ii++) {
      bounds.extend(path[ii])
    }
  }

  return bounds
}

export const round = (num, decimalPlaces = 0) => {
  return Number(
    Math.round(Number(num + "e" + decimalPlaces)) + "e-" + decimalPlaces
  )
}

export const lowerCaseAllWordsExceptFirstLetters = (string) => {
  if (!string) return

  return string.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  )
}

export const isScreenLGAndBigger = () => {
  return window.outerWidth > 1240
}

export const mapLocationToMenuKey = () => {
  return window.location.pathname.replace("/", "")
}

export const openInNewTab = (url) => {
  window.open(url, "_blank", "noopener,noreferrer")
}

export const isEventCreatedFromSystem = (event) => {
  return event.creator.username === SYSTEM_USERNAME
}

export const hasBadCharachters = (text) => {
  if (!text) return

  try {
    new RegExp(text)
    return false
  } catch (error) {
    return true
  }
}

export const isArrayBuffer = (value) => {
  return (
    value instanceof ArrayBuffer ||
    Object.prototype.toString.call(value) === "[object ArrayBuffer]"
  )
}

export const tableRowCellWrapper = (
  text,
  shouldCallPopover = false,
  isUnread = false
) => {
  const styles = {
    tableRowCell: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "200px",
    },
  }
  return (
    <div
      style={{
        ...styles.tableRowCell,
        ...{ fontWeight: isUnread ? "bold" : "normal" },
      }}
    >
      {shouldCallPopover ? (
        <Popover placement={"topLeft"} content={text}>
          {text}
        </Popover>
      ) : (
        <>{text}</>
      )}
    </div>
  )
}

export const getKeyFromObject = (object, key) => {
  if (object == null) return null
  if (object.hasOwnProperty(key)) {
    return object[key]
  }

  return null
}
