import React from "react"
import { connect } from "react-redux"

import {
  retrieveDeliverableForm,
  patchDeliverable,
} from "../../actions/deliverableAction"
import {
  UploadOutlined
} from "@ant-design/icons"
import { Button, Col, Divider, Checkbox, Input, Modal, Radio, Row, Table, InputNumber, Cascader, Upload, message } from "antd"
import DeliverableAccordation from "../../../common/components/DeliverableAccordation"
import { getKeyFromObject } from "../../../common/utils/utils"
import Loader from "../../../common/components/Loader"
import { api } from "../../../common/api/api"

class EditDeliverable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      form: {
        questionnaire: null,
        deliverabletwoauditcheck: null,
        tax_number: this.props.taxNumber,
        deliverable_code: this.props.deliverableCode,
        advice_code: this.props.adviceCode,
      },
      fileList: []
    }

    this.props.retrieveDeliverableForm(
      this.props.deliverableCode,
      this.props.adviceCode,
      this.props.taxNumber
    )
  }

  onSaveClicked = () => {
    this.props.patchDeliverable(this.props.taxNumber, this.state.form)
  }

  changeQuestionnaireAnswer = (question, answer) => {
    if (this.state.form.questionnaire?.[question.question_id] == answer) {
      let q = _.cloneDeep(this.state.form.questionnaire)
      delete q[question.question_id]
      this.setState({
        form: {
          ...this.state.form,
          questionnaire: {
            ...q,
          },
        },
      })
    } else {
      this.setState({
        form: {
          ...this.state.form,
          questionnaire: {
            ...this.state.form.questionnaire,
            [question.question_id]: answer,
          },
        },
      })
    }
  }

  renderQuestion = (question) => {
    return (
      <>
        <div style={{ marginBottom: "36px", marginTop: "4px" }}>
          <Row>
            <Col span={3}>
              <b>{question.code}</b>
            </Col>
            <Col span={21}>
              <b>{question.title}</b>
            </Col>
          </Row>
          <Row>
            <Col span={3}></Col>
            <Col span={21}>
              <Radio.Group
                defaultValue={
                  question.answer
                    ? question.answer
                    : this.state.form.questionnaire?.[question.question_id]
                      ? this.state.form.questionnaire[question.question_id]
                      : null
                }
                buttonStyle="solid"
              // disabled={question.answer}
              // value={
              //   question.answer
              //     ? question.answer
              //     : this.state.form.questionnaire?.[question.question_id]
              //     ? this.state.form.questionnaire[question.question_id]
              //     : null
              // }
              >
                <Radio.Button
                  onClick={(e) =>
                    this.changeQuestionnaireAnswer(question, e.target.value)
                  }
                  value="ΝΑΙ"
                >
                  ΝΑΙ
                </Radio.Button>
                <Radio.Button
                  onClick={(e) =>
                    this.changeQuestionnaireAnswer(question, e.target.value)
                  }
                  value="ΟΧΙ"
                >
                  ΟΧΙ
                </Radio.Button>
                <Radio.Button
                  onClick={(e) =>
                    this.changeQuestionnaireAnswer(question, e.target.value)
                  }
                  value="Δ/Α"
                >
                  Δ/Α
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </div>
      </>
    )
  }

  audit_check_columns = [
    {
      key: "audit_check.code",
      title: () => <span style={{ whiteSpace: 'nowrap' }}>Α/Α</span>,
      render: (record) => record.audit_check.code
    },
    {
      key: "audit_check.check_point",
      title: "Σημείο ελέγχου",
      render: (record) => record.audit_check.check_point
    },
    {
      key: "audit_check.compliance_criterion",
      title: "Κριτήριο συμμόρφωσης",
      render: (record) => record.audit_check.compliance_criterion
    },
    {
      key: "yes",
      title: "Ναι",
      render: (record) => <Checkbox
        defaultChecked={record.yes}
        checked={getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id)?.yes ?? record.yes}
        onChange={(e) => {
          const innerObj = getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id) ?? {}
          this.setState({
            form: {
              ...this.state.form,
              deliverabletwoauditcheck: {
                ...this.state.form.deliverabletwoauditcheck,
                [record.id]: {
                  ...innerObj,
                  yes: e.target.checked, no: !e.target.checked
                }
              }
            }
          })
        }
        }

        disabled={!record.audit_check.can_insert_yes} />
    },
    {
      key: "no",
      title: "Όχι",
      render: (record) => <Checkbox
        checked={getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id)?.no ?? record.no}
        onChange={(e) => {
          const innerObj = getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id) ?? {}
          this.setState({
            form: {
              ...this.state.form,
              deliverabletwoauditcheck: {
                ...this.state.form.deliverabletwoauditcheck,
                [record.id]: {
                  ...innerObj,
                  yes: !e.target.checked, no: e.target.checked
                }
              }
            }
          })
        }
        }
        disabled={!record.audit_check.can_insert_no} />
    },
    {
      key: "number_of_actions",
      title: "Αριθμός ενεργειών",
      render: (record) => <InputNumber
        defaultValue={record.number_of_actions}
        min={0}
        max={100}
        onChange={(value) => {
          const innerObj = getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id) ?? {}
          this.setState({
            form: {
              ...this.state.form,
              deliverabletwoauditcheck: {
                ...this.state.form.deliverabletwoauditcheck,
                [record.id]: {
                  ...innerObj,
                  number_of_actions: value
                }
              }
            }
          })
        }}
        disabled={!record.audit_check.can_insert_number_actions} />
    },
    {
      key: "documentations",
      title: "Τεκμηρίωση περιγραφή",
      render: (record) => {
        let isEditable = false
        const isNoSelected = getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id)?.no ?? record.no
        const isYesSelected = getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id)?.yes ?? record.yes
        if (
          (record.audit_check.if_no_enable_documentation && isNoSelected) ||
          (record.audit_check.if_yes_enable_documentation && isYesSelected)
        ) {
          isEditable = true
        }

        return <>
          {record.audit_check.documentation_subtitle ?
            <>
              <label style={{ fontWeight: 500, fontSize: 16 }}>{record.audit_check.documentation_subtitle}:</label>
              <br />
              <br />
            </>
            : <></>
          }

          {record.audit_check_documentations.map(audit_check_documentation => {
            if (audit_check_documentation.audit_check_documentation.input_type == "choice") {
              return (
                <>
                  <Checkbox
                    key={`${audit_check_documentation.choice_value}-${audit_check_documentation.audit_check_documentation.title}`}
                    disabled={!isEditable || audit_check_documentation.audit_check_documentation.is_auto_calculated}
                    onChange={(e) => {
                      const innerObj = getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id) ?? {}
                      this.setState({
                        form: {
                          ...this.state.form,
                          deliverabletwoauditcheck: {
                            ...this.state.form.deliverabletwoauditcheck,
                            [record.id]: {
                              ...innerObj,
                              audit_check_documentations: {
                                ...innerObj.audit_check_documentations,
                                [audit_check_documentation.id]: { choice_value: e.target.checked }
                              }
                            }
                          }
                        }
                      })
                    }}>
                    {audit_check_documentation.audit_check_documentation.title}
                  </Checkbox>
                  <Divider></Divider>
                </>
              )
            } else if (audit_check_documentation.audit_check_documentation.input_type == "text") {
              return (
                <div>
                  <label>{audit_check_documentation.audit_check_documentation.title}</label>
                  <Input
                    key={`${audit_check_documentation.text_value}-${audit_check_documentation.audit_check_documentation.title}`}
                    disabled={!isEditable || audit_check_documentation.audit_check_documentation.is_auto_calculated}
                    defaultValue={audit_check_documentation.text_value}
                    onChange={(e) => {
                      const innerObj = getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id) ?? {}
                      this.setState({
                        form: {
                          ...this.state.form,
                          deliverabletwoauditcheck: {
                            ...this.state.form.deliverabletwoauditcheck,
                            [record.id]: {
                              ...innerObj,
                              audit_check_documentations: {
                                ...innerObj.audit_check_documentations,
                                [audit_check_documentation.id]: { text_value: e.target.value }
                              }
                            }
                          }
                        }
                      })
                    }} />
                  <Divider></Divider>
                </div>
              )
            } else if (audit_check_documentation.audit_check_documentation.input_type == "field") {
              return (
                <div>
                  <p
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    Φυτικό είδος
                  </p>
                  <Cascader
                    key={`${audit_check_documentation.audit_check_documentation.title}`}
                    disabled={!isEditable || audit_check_documentation.audit_check_documentation.is_auto_calculated}
                    title="Αγροτεμάχια"
                    placement={"topLeft"}
                    multiple={true}
                    defaultValue={audit_check_documentation.field_ids?.map(field_id => [field_id])}
                    style={{ width: "100%" }}
                    allowClear={true}
                    options={this.props.fields}
                    onChange={(value) => {
                      const field_ids = value.map((val) => {
                        return val[0]
                      })
                      const innerObj = getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id) ?? {}
                      this.setState({
                        form: {
                          ...this.state.form,
                          deliverabletwoauditcheck: {
                            ...this.state.form.deliverabletwoauditcheck,
                            [record.id]: {
                              ...innerObj,
                              audit_check_documentations: {
                                ...innerObj.audit_check_documentations,
                                [audit_check_documentation.id]: { field_ids: field_ids }
                              }
                            }
                          }
                        }
                      })
                    }}
                  />
                </div>
              )
            } else if (audit_check_documentation.audit_check_documentation.input_type == "livestock") {
              return (
                <div>
                  <p
                    style={{
                      marginTop: "16px",
                    }}
                  >
                    Ζωικό είδος
                  </p>
                  <Cascader
                    key={`${audit_check_documentation.audit_check_documentation.title}`}
                    disabled={!isEditable || audit_check_documentation.audit_check_documentation.is_auto_calculated}
                    title="Ζωικό κεφάλαιο"
                    placement={"topLeft"}
                    multiple
                    style={{ width: "100%" }}
                    allowClear={false}
                    defaultValue={audit_check_documentation.livestock_ids?.map(livestock_id => [livestock_id])}
                    options={this.props.livestocks}
                    onChange={(value) => {
                      const livestock_ids = value.map((val) => {
                        return val[0]
                      })
                      const innerObj = getKeyFromObject(this.state.form.deliverabletwoauditcheck, record.id) ?? {}
                      this.setState({
                        form: {
                          ...this.state.form,
                          deliverabletwoauditcheck: {
                            ...this.state.form.deliverabletwoauditcheck,
                            [record.id]: {
                              ...innerObj,
                              audit_check_documentations: {
                                ...innerObj.audit_check_documentations,
                                [audit_check_documentation.id]: { livestock_ids: livestock_ids }
                              }
                            }
                          }
                        }
                      })
                    }}
                  />
                </div>
              )
            } else if (audit_check_documentation.audit_check_documentation.input_type == "upload") {
              return (
                <div>
                  <label>{audit_check_documentation.audit_check_documentation.title}</label>
                  <div>
                    <Upload
                      {...{
                        showUploadList: {
                          showDownloadIcon: false,
                          showRemoveIcon: false,
                          previewIcon: false,
                        },
                        customRequest: (
                          {
                            file,
                            onSuccess,
                            onError,
                            onProgress
                          }
                        ) => this.customRequestImplementation(
                          file,
                          onSuccess,
                          onError,
                          onProgress, record.id, audit_check_documentation.id
                        ),
                        fileList: this.state.fileList.length == 0 ? audit_check_documentation.file ? [audit_check_documentation.file] : this.state.fileList : this.state.fileList,
                        onChange: (info) => {
                          if (info.file.status === "done") {
                            message.success(
                              `Το αρχείο "${info.file.name}" μεταφορτώθηκε.`
                            )
                            this.setState({ fileList: [info.file] })
                          } else {
                            this.setState({ fileList: [info.file] })
                          }
                        },
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Μεταφόρτωση αρχείου</Button>
                    </Upload>
                  </div>
                  <Divider></Divider>
                </div>
              )
            } else {
              return <h4 style={{ color: 'gray' }}>Not implemented</h4>
            }
          })}
        </>
      }
    },
  ]

  customRequestImplementation = async (
    file,
    onSuccess,
    onError,
    onProgress,
    auditCheckId,
    documentationId
  ) => {
    console.log("customRequestImplementation", file)
    const response = await api
      .post(
        `printouts/deliverable/${this.props.taxNumber}/audit-check/${auditCheckId}/documentation/${documentationId}/${file.name}`,
        file,
        {
          onUploadProgress: (progressEvent) => {
            const percent = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            )
            onProgress({ percent })
          },
        }
      )
      .catch((error) => {
        onError(error)
      })

    onSuccess(response.data)
  }

  renderAuditCheck = (auditCheckList) => {
    return (
      <DeliverableAccordation title={"Φύλλο ελέγχου ενεργειών εργασιών"}>
        <Table
          pagination={false}
          bordered
          columns={this.audit_check_columns}
          dataSource={auditCheckList}
        />
      </DeliverableAccordation>
    )
  }

  renderQuestionnaire = (questionnaireList) => {
    return (
      <DeliverableAccordation title={"Ερωτηματολόγιο"}>
        {questionnaireList.map((category) => {
          return (
            <>
              <Divider></Divider>
              <Row>
                <Col span={3}>
                  <b>{category.code}</b>
                </Col>
                <Col span={21}>
                  <b>{category.title}</b>
                </Col>
              </Row>
              <Divider></Divider>
              {category.question_subcategory.map((subcategory) => {
                let subcategoryHeader = <></>
                if (
                  subcategory.code !== category.code &&
                  subcategory.title != category.title
                ) {
                  subcategoryHeader = (
                    <>
                      <Divider></Divider>
                      <Row>
                        <Col span={3}>
                          <b>{subcategory.code}</b>
                        </Col>
                        <Col span={21}>
                          <b>{subcategory.title}</b>
                        </Col>
                      </Row>
                      <Divider></Divider>
                    </>
                  )
                }

                return (
                  <>
                    {subcategoryHeader}
                    {subcategory.questions.map((question) =>
                      this.renderQuestion(question)
                    )}
                  </>
                )
              })}
            </>
          )
        })}
      </DeliverableAccordation>
    )
  }

  renderIacsData = (iacsData) => {
    return <></>
  }

  renderHolding = (holding) => {
    return (
      <>
        <DeliverableAccordation title={"Έδρα εκμετάλλευσης"}>
          {Object.entries(holding).map(([key, field]) => (
            <div style={styles.spaceBetween}>
              <b>{field.title}</b>
              <Input
                onChange={(e) => {
                  // this.setState({
                  //   form: { ...this.state.form, [field]: e.target.value },
                  // })
                }}
                defaultValue={field.value}
                placeholder={field.value}
                disabled={field.read_only}
                maxLength={field.max_length}
                className="inputs"
                style={{ height: "4vh" }}
              />
            </div>
          ))}
        </DeliverableAccordation>
      </>
    )
  }

  renderForm = (nestedRenderingPayload = null) => {
    if (!this.props.form) {
      return <Loader />
    }

    return Object.entries(nestedRenderingPayload ? nestedRenderingPayload : this.props.form).map(([keyParent, section]) => {
      if (!section) {
        return <></>
      }

      let sectionKeys = []
      let sectionValues = section
      if (!Array.isArray(section)) {
        sectionKeys = Object.keys(section)
        sectionValues = Object.values(section)
      }

      switch (keyParent) {
        case "questionnaire":
          return this.renderQuestionnaire(sectionValues)
        case "deliverabletwoauditcheck":
          return this.renderAuditCheck(sectionValues)
        case "field":
        case "livestock":
          return this.renderIacsData(section)
        case "holding":
          return this.renderHolding(sectionValues)
        case "content":
          return this.renderForm(section)
      }

      const extras = getKeyFromObject(section, "extras")

      return (
        <DeliverableAccordation title={extras ? extras.title : "Περισσότερα"}>
          {Object.entries(section).map(([key, obj]) => {
            if (key == "extras") return <></>
            return (
              <div style={styles.spaceBetween}>
                {
                  obj.type == "checkbox" ?
                    <Checkbox
                      onChange={(e) => {
                        this.setState({
                          form: {
                            ...this.state.form,
                            [keyParent]: {
                              ...this.state.form[keyParent],
                              [key]: e.target.checked,
                            },
                          },
                        })
                      }}
                      defaultChecked={obj.value}
                      placeholder={obj.title}
                      disabled={obj.read_only}
                      maxLength={obj.max_length}
                    >
                      {obj.title}
                    </Checkbox>
                    :
                    <>
                      <b>{obj.title}</b>
                      <Input.TextArea
                        onChange={(e) => {
                          this.setState({
                            form: {
                              ...this.state.form,
                              [keyParent]: {
                                ...this.state.form[keyParent],
                                [key]: e.target.value,
                              },
                            },
                          })
                        }}
                        defaultValue={obj.value}
                        placeholder={obj.title}
                        disabled={obj.read_only}
                        maxLength={obj.max_length}
                        style={{
                          height: obj.max_length > 1000 ? "24vh" : "8vh",
                        }}
                      />
                    </>
                }
              </div>
            )
          })}
        </DeliverableAccordation>
      )
    })
  }

  render() {
    return (
      <Modal
        width={"90%"}
        footer={[
          <Button key="ok" type="primary" onClick={this.props.closeModal}>
            Πίσω
          </Button>,
        ]}
        open={true}
        onCancel={() => this.props.closeModal()}
        onOk={() => this.props.closeModal()}
      >
        <Divider>Επεξεργασία παραδοτέου</Divider>
        {this.renderForm()}
        <Button style={{ marginTop: "32px" }} onClick={this.onSaveClicked}>
          Αποθήκευση
        </Button>
      </Modal>
    )
  }
}

const styles = {
  spaceBetween: {
    marginTop: "16px",
  },
}

const mapStateToProps = (state) => {
  return {
    form: state.deliverable.deliverableForm,

    fields: state.farmer.fieldsForSelection,
    livestocks: state.farmer.livestocksForSelection
  }
}

export default connect(mapStateToProps, {
  retrieveDeliverableForm,
  patchDeliverable,
})(EditDeliverable)
