import React from "react"
import { connect } from "react-redux"

import { loadAuth } from "./actions/authAction"
import { primaryColor } from "../common/colors"

import el_GR from "antd/locale/el_GR"
import { ConfigProvider } from "antd"

import { createBrowserRouter, RouterProvider } from "react-router-dom"

import PrivateComponent from "./components/PrivateComponent"
import { notificationListener } from "../common/utils/notification"
import CopyrightFooter from "../common/components/footer/CopyrightFooter"

import Loader from "../common/components/Loader"

import FarmerLoginScreen from "./screens/FarmerLoginScreen"
import MessageScreen from "./screens/MessageScreen"
import NotFoundPage from "../common/components/NotFoundPage"
import FieldScreen from "./screens/FieldScreen"

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateComponent>
        <MessageScreen />
      </PrivateComponent>
    ),
  },
  {
    path: "/fields",
    element: (
      <PrivateComponent>
        <FieldScreen />
      </PrivateComponent>
    ),
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
])

class Root extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.loadAuth()
  }

  render() {
    if (this.props.isLoggedIn == null) {
      return <Loader absoluteCenter={true} />
    }

    return (
      <>
        <ConfigProvider
          locale={el_GR}
          theme={{
            token: {
              colorPrimary: primaryColor,
              fontSize: 15,
              lineWidth: 2,
              colorText: "black",
              colorInfo: primaryColor,
              lineWidth: 2,
            },
            components: {
              Input: {
                colorPrimary: primaryColor,
              },
              Radio: {
                colorPrimary: primaryColor,
              },
              Button: {
                colorPrimary: primaryColor,
              },
              Card: {
                colorPrimary: primaryColor,
              },
              Tabs: {
                colorPrimary: primaryColor,
              },
              Modal: {
                colorPrimary: primaryColor,
              },
              Switch: {
                colorPrimary: primaryColor,
              },
              Table: {
                colorPrimary: primaryColor,
              },
            },
          }}
        >
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {notificationListener()}
            {this.props.isLoggedIn ? (
              this.renderPrivateNavigation()
            ) : (
              <FarmerLoginScreen />
            )}
          </div>

          <div style={{ marginTop: "200px" }}>{/* <CopyrightFooter /> */}</div>
        </ConfigProvider>
      </>
    )
  }

  renderPrivateNavigation = () => {
    return <RouterProvider router={router} />
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.farmerAuth.isLoggedIn,
    notification: state.notifications.notification,
    profile: state.farmerAuth.profile,
  }
}

export default connect(mapStateToProps, { loadAuth })(Root)
