import React from "react"
import { connect } from "react-redux"

import { loadAuth } from "./actions/authAction"
import { primaryColor } from "../common/colors"

import el_GR from "antd/locale/el_GR"
import { ConfigProvider } from "antd"

import { createBrowserRouter, RouterProvider } from "react-router-dom"

import PrivateComponent from "./components/PrivateComponent"
import { notificationListener } from "../common/utils/notification"
import CopyrightFooter from "../common/components/footer/CopyrightFooter"
import AdviceObligation from "./screens/AdviceObligation"
import RegisterEmployee from "./screens/RegisterEmployee"
import Loader from "../common/components/Loader"
import TenantLoginScreen from "./screens/TenantLoginScreen"
import TenantEmployees from "./screens/TenantEmployees"
import AdviceObligationActions from "./screens/employee/AdviceObligationActions"

import { getProfile } from "./actions/authAction"
import NotFoundPage from "../common/components/NotFoundPage"
import SessionsScreen from "./screens/employee/SessionsScreen"
import AdviceObligationCancellation from "./screens/AdviceObligationCancellation"
import SendMessageScreen from "./screens/messages/SendMessageScreen"
import MessageHistoryScreen from "./screens/messages/MessageHistoryScreen"


const routerAdmin = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateComponent>
        <AdviceObligation />
      </PrivateComponent>
    ),
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/registerEmployee",
    element: (
      <PrivateComponent>
        <RegisterEmployee />
      </PrivateComponent>
    ),
  },
  {
    path: "/employees",
    element: (
      <PrivateComponent>
        <TenantEmployees />
      </PrivateComponent>
    ),
  },
  {
    path: "/obligation/:id",
    element: (
      <PrivateComponent>
        <AdviceObligationActions />
      </PrivateComponent>
    ),
  },
  {
    path: "/sessions",
    element: (
      <PrivateComponent>
        <SessionsScreen />
      </PrivateComponent>
    ),
  },
  {
    path: "/cancellations",
    element: (
      <PrivateComponent>
        <AdviceObligationCancellation />
      </PrivateComponent>
    ),
  },
])

const routerEmployee = createBrowserRouter([
  {
    path: "/",
    element: (
      <PrivateComponent>
        <AdviceObligation />
      </PrivateComponent>
    ),
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/messageSend",
    element: (
      <PrivateComponent>
        <SendMessageScreen />
      </PrivateComponent>
    ),
  },
  {
    path: "/messageHistory",
    element: (
      <PrivateComponent>
        <MessageHistoryScreen />
      </PrivateComponent>
    ),
  },
  {
    path: "/obligation/:id",
    element: (
      <PrivateComponent>
        <AdviceObligationActions />
      </PrivateComponent>
    ),
  },
  {
    path: "/sessions",
    element: (
      <PrivateComponent>
        <SessionsScreen />
      </PrivateComponent>
    ),
  },
  {
    path: "/cancellations",
    element: (
      <PrivateComponent>
        <AdviceObligationCancellation />
      </PrivateComponent>
    ),
  },
])

class Root extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.loadAuth()
  }

  render() {
    if (this.props.isLoggedIn == null) {
      return <Loader absoluteCenter={true} />
    }

    return (
      <>
        <ConfigProvider
          locale={el_GR}
          theme={{
            token: {
              colorPrimary: primaryColor,
              fontSize: 13,
              lineWidth: 1.4,
              colorText: "black",
              colorInfo: primaryColor,
              lineWidth: 1.2,
            },
            components: {
              Input: {
                colorPrimary: primaryColor,
              },
              Radio: {
                colorPrimary: primaryColor,
              },
              Button: {
                colorPrimary: primaryColor,
              },
              Card: {
                colorPrimary: primaryColor,
              },
              Tabs: {
                colorPrimary: primaryColor,
              },
              Modal: {
                colorPrimary: primaryColor,
              },
              Switch: {
                colorPrimary: primaryColor,
              },
              Table: {
                colorPrimary: primaryColor,
              },
            },
          }}
        >
          <div
            style={{
              // minHeight: "100vh",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {notificationListener()}
            {this.props.isLoggedIn ? (
              this.renderPrivateNavigation()
            ) : (
              <TenantLoginScreen />
            )}
          </div>
        </ConfigProvider>
      </>
    )
  }

  renderPrivateNavigation = () => {
    if (!this.props.profile) {
      this.props.getProfile()
      return <Loader absoluteCenter={true} />
    }

    let router

    if (this.props.profile.role == "tenant_employee") {
      router = routerEmployee
    } else {
      router = routerAdmin
    }

    return <RouterProvider router={router} />
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.tenantAuth.isLoggedIn,
    notification: state.notifications.notification,
    profile: state.tenantAuth.profile,
  }
}

export default connect(mapStateToProps, { loadAuth, getProfile })(Root)
