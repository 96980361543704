import axios from "axios"
import { API_URL } from "../config"
import store from "../../store"
import { isArrayBuffer } from "../utils/utils"
import { showNotification } from "../actions/notificationAction"
import { onLogout } from "../actions/authAction"

export const unauthorizedApi = axios.create({
  baseURL: API_URL,
})

export const api = axios.create({
  baseURL: API_URL,
})

api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token")

    config.headers.Authorization = `Token ${token}`

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
)

const responseSuccessHandler = (response) => {
  if (response.status == 401) {
    store.dispatch(onLogout())
  }
  return response
}

const responseErrorHandler = (error) => {
  const data = cleanErrorData(error)
  if (error.response.status === 401) {
    store.dispatch(
      showNotification("Αποτυχία", "Η σύνδεση σας έχει λήξει", "error")
    )
    store.dispatch(onLogout())
    return {}
  } else {
    if (data.detail) {
      store.dispatch(showNotification("Αποτυχία", data.detail, "error"))
    }
  }

  return Promise.reject(error)
}

const cleanErrorData = (error) => {
  if (error.response) {
    const responseData = error.response.data

    if (isArrayBuffer(responseData)) {
      const decodedValue = new TextDecoder().decode(error.response.data)
      return JSON.parse(decodedValue)
    } else if (typeof responseData === "string") {
      return JSON.parse(responseData)
    }

    // If not ArrayBuffer and not a string, return the data as it is JSON
    return responseData
  }

  return error.message
}
