import React from "react"

import { Divider, Popover } from "antd"
import { InfoCircleOutlined } from "@ant-design/icons"
import { primaryColor } from "../../../common/colors"

class PageHeader extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  renderInfoPopover = () => {
    return (
      <Popover
        content={
          <div
            style={{
              maxWidth: "30vw",
              wordWrap: "break-word",
            }}
          >
            {this.props.infoDescription}
          </div>
        }
        trigger="click"
      >
        <InfoCircleOutlined
          style={{
            color: primaryColor,
            marginRight: "5%",
          }}
        />
      </Popover>
    )
  }

  render() {
    return (
      <Divider style={{ fontSize: "17px" }}>
        {this.props.infoDescription ? this.renderInfoPopover() : ""}
        {this.props.children}
      </Divider>
    )
  }
}

export default PageHeader
