import React from "react"
import Cultivation from "./Cultivation"
import { Col, Divider, Row } from "antd"

class CultivationList extends React.Component {
  renderCultivation = (item, index) => {
    return (
      <Row style={styles.rowStyle}>
        <Col span={24}>
          <Cultivation cultivation={item} index={index + 1} />
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <>
        <Divider>Ποικιλίες</Divider>
        <div style={styles.cultivationContainer}>
          {this.props.cultivations.map((item, index) => {
            return this.renderCultivation(item, index)
          })}
        </div>
      </>
    )
  }
}

const styles = {
  rowStyle: {
    marginTop: "1%",
  },
  cultivationContainer: {
    marginTop: "42px",
  },
}

export default CultivationList
