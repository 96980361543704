import React from "react"
import ReactDOM from "react-dom"

import "./css/App.css"
import "./css/Table.css"
import RootTenant from "./tenant/Root"
import RootFarmer from "./farmer/Root"
import { Provider } from "react-redux"
import store from "./store"
import LandPage from "./LandPage"

import moment from "moment"
import "moment/min/locales"
import { DATE_FORMAT } from "./common/config"
import dayjs from "dayjs"
import 'dayjs/locale/el'
moment.locale("el")
moment.defaultFormat = DATE_FORMAT
dayjs.locale('el')

const App = () => {
  if (!localStorage.getItem("role")) {
    return <LandPage />
  }

  return localStorage.getItem("role") == "tenant" ? (
    <RootTenant />
  ) : (
    <RootFarmer />
  )
}

const AppWrapper = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

import { createRoot } from 'react-dom/client'
const domNode = document.getElementById("root")
const root = createRoot(domNode)
root.render(<AppWrapper />)