import { Button, Card, Col, Descriptions, Divider, Row } from "antd"
import React from "react"


import {
  getAdviceObligationById,
  cleanAdviceObligation,
} from "../../actions/adviceAction"
import { connect } from "react-redux"
import DeliverableList from "../../components/DeliverableList"
import MessageList from "../../components/MessageList"
import MessageSendModal from "../../components/MessageSendModal"
import Loader from "../../../common/components/Loader"
import TimeWastedModal from "../../components/TimeWastedModal"
import PageHeader from "../../components/custom/PageHeader"
import { DELIVERABLE_TEXT, MESSAGE_TEXT } from "../../../common/texts"
import { getDeliverables } from "../../actions/deliverableAction"

class AdviceObligationActions extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      adviceObligationId: window.location.pathname.split("/")[2],
      sendMessageModalOpen: false,

      timeWastedModalOpen: false,
    }
  }

  componentDidMount() {
    this.props.getAdviceObligationById(this.state.adviceObligationId)
  }

  componentWillUnmount() {
    this.props.cleanAdviceObligation()
  }

  renderDeliverableSection = () => {
    return (
      <>
        <PageHeader infoDescription={DELIVERABLE_TEXT}>Παραδοτέα</PageHeader>
        <DeliverableList
          taxNumber={this.props.adviceObligationObject.farmer.tax_number}
          adviceCode={this.props.adviceObligationObject.advice.code}
        />
      </>
    )
  }

  renderMessageSection = () => {
    return (
      <>
        <PageHeader infoDescription={MESSAGE_TEXT}>Μηνύματα</PageHeader>
        <Button onClick={() => this.setState({ sendMessageModalOpen: true })}>
          Αποστολή μηνύματος
        </Button>
        {this.state.sendMessageModalOpen ? (
          <MessageSendModal
            closeModal={() => this.setState({ sendMessageModalOpen: false })}
            taxNumber={this.props.adviceObligationObject.farmer.tax_number}
          />
        ) : (
          <></>
        )}
        <MessageList
          taxNumber={this.props.adviceObligationObject.farmer.tax_number}
        />
      </>
    )
  }

  renderAdvice = () => {
    return (
      <Card
        type="inner"
        style={{ marginTop: "24px", height: "100%" }}
        title="Συμβουλή"
      >
        <Descriptions
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Τίτλος",
              span: 24,
              children: `${this.props.adviceObligationObject.advice.code} ${this.props.adviceObligationObject.advice.title}`,
            },
            {
              key: "2",
              label: "Περιγραφή",
              span: 24,
              children: `${this.props.adviceObligationObject.advice.description}`,
            },
          ]}
        />

        <Button onClick={() => this.setState({ timeWastedModalOpen: true })}>
          Προσθήκη χρόνου που αφιερώθηκε
        </Button>
        {this.state.timeWastedModalOpen ? (
          <TimeWastedModal
            deliverables={this.props.deliverables}
            adviceObligationId={this.state.adviceObligationId}
            closeModal={() => this.setState({ timeWastedModalOpen: false })}
          />
        ) : (
          <></>
        )}
      </Card>
    )
  }

  renderFarmer = () => {
    return (
      <Card
        type="inner"
        style={{ marginTop: "24px", height: "100%" }}
        title="Ωφελούμενος"
      >
        <Descriptions
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Ονοματεπώνυμο",
              span: 24,
              children: `${this.props.adviceObligationObject.farmer.first_name} ${this.props.adviceObligationObject.farmer.last_name}`,
            },
            {
              key: "2",
              label: "Α.Φ.Μ.",
              span: 24,
              children: `${this.props.adviceObligationObject.farmer.tax_number}`,
            },
            {
              key: "3",
              label: "Ηλεκτρονική Διεύθυνση ",
              span: 24,
              children: `${this.props.adviceObligationObject.farmer.email}`,
            },
          ]}
        />
      </Card>
    )
  }

  render() {
    if (!this.props.adviceObligationObject) {
      return <Loader absoluteCenter />
    }

    return (
      <>
        <Row gutter={[40, 40]}>
          <Col span={12}>{this.renderFarmer()}</Col>

          <Col span={12}>{this.renderAdvice()}</Col>
        </Row>

        <Row gutter={[40, 40]}>
          <Col span={12}>{this.renderDeliverableSection()}</Col>

          <Col span={12}>{this.renderMessageSection()}</Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    adviceObligationObject: _.cloneDeep(state.advice.adviceObligationObject),
    deliverables: state.deliverable.deliverables
  }
}

export default connect(mapStateToProps, {
  getDeliverables,
  getAdviceObligationById,
  cleanAdviceObligation,
})(AdviceObligationActions)
