import { Button, Card, Cascader, Col, Form, Input, Row, Tabs, Upload } from "antd";
import React from "react";
import PageHeader from "../../components/custom/PageHeader";
import { connect } from "react-redux";
import SendByAdviceScreen from "./SendByAdviceScreen";
import { SEND_MESSAGE_TEXT } from "../../../common/texts";

import {
    SolutionOutlined
} from "@ant-design/icons"

class SendMessageScreen extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            messageForm: {}
        }
    }

    tabs = [
        {
            icon: <SolutionOutlined />,
            key: "advice",
            label: "Ανά συμβουλή",
            children: <SendByAdviceScreen />
        }
    ]

    render() {
        return (
            <>
                <PageHeader infoDescription={SEND_MESSAGE_TEXT} >
                    Αποστολή μηνύματος
                </PageHeader>

                <Tabs
                    items={this.tabs}
                />
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        advices: state.tenantAuth.tenantProfile?.advices
    }
}

export default connect(mapStateToProps, {})(SendMessageScreen)