export const transformQuestionnaire = (questions) => {
  let transformedQuestionnaire = []

  let categoryFlagCode = null
  let subcategoryFlagCode = null

  let categoryDictCursor = -1
  let subcategoryDictCursor = 0

  for (let i = 0; i < questions.length; i++) {
    let question = questions[i]
    let cursorSubcategory = question.question_subcategory
    let cursorCategory = question.question_category

    if (cursorCategory.code === categoryFlagCode) {
      if (cursorSubcategory.code === subcategoryFlagCode) {
        // Question belongs to the current category and subcategory
        transformedQuestionnaire[categoryDictCursor]["question_subcategory"][
          subcategoryDictCursor
        ]["questions"].push(question)
      } else {
        // Question belongs to the current category, but we have a new subcategory
        transformedQuestionnaire[categoryDictCursor]["question_subcategory"].push({
          code: cursorSubcategory.code,
          title: cursorSubcategory.title,
          questions: [question],
        })
        subcategoryDictCursor++
        subcategoryFlagCode = cursorSubcategory.code
      }
    } else {
      // New category case
      categoryFlagCode = cursorCategory.code
      subcategoryFlagCode = cursorSubcategory.code

      categoryDictCursor++
      subcategoryDictCursor = 0

      transformedQuestionnaire.push({
        code: cursorCategory.code,
        title: cursorCategory.title,
        question_subcategory: [
          {
            code: cursorSubcategory.code,
            title: cursorSubcategory.title,
            questions: [question],
          },
        ],
      })
    }
  }

  return transformedQuestionnaire
}
