import {
  CLEAN_MESSAGE_LIST,
  MESSAGE_HISTORY_LIST,
  MESSAGE_LIST,
  MESSAGE_SEND_CLEAN,
  MESSAGE_SEND_FAILED,
  MESSAGE_SEND_SUCCESS,
} from "../actions/types"

const INIT_STATE = {}

const messageReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case MESSAGE_LIST:
      return {
        ...state,
        messageList: action.payload.messages,
      }
    case MESSAGE_HISTORY_LIST:
      return {
        ...state,
        messageHistoryList: action.payload.messages,
      }
    case MESSAGE_SEND_SUCCESS:
      return {
        ...state,
        sentSuccessfully: true,
      }
    case MESSAGE_SEND_FAILED:
      return {
        ...state,
        sentFailed: true,
      }
    case MESSAGE_SEND_CLEAN:
      return {
        ...state,
        sentSuccessfully: null,
        sentFailed: null,
      }
    case CLEAN_MESSAGE_LIST:
      return {
        ...state,
        messageList: null,
      }
    default:
      return state
  }
}

export default messageReducer
