import {
  ADVICE_OBLIGATION_CREATE_FAILED,
  ADVICE_OBLIGATION_CREATE_SUCCEED,
  ADVICE_OBLIGATION_CREATE_CLEAN,
  ADVICE_OBLIGATION_LIST,
  ADVICE_PACK_LIST,
  ADVICE_OBLIGATION_OBJECT,
  CLEAN_ADVICE_OBLIGATION_OBJECT,
  TIME_WASTED_SESSIONS,
  TIME_WASTED_FINISHED,
  CLEAN_TIME_WASTED_FINISHED,
  ALL_TIME_WASTED_SESSIONS,
  EXPORT_SUCCESS,
  CLEAN_EXPORT_SUCCESS,
  ADVICE_OBLIGATION_CANCELLATION_LIST,
  ADVICE_OBLIGATION_CANCELLATION_SUCCEED,
  ADVICE_OBLIGATION_CANCELLATION_FAILED,
  ADVICE_OBLIGATION_CANCELLATION_CLEAN
} from "../actions/types"

const INIT_STATE = {}

const adviceReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADVICE_OBLIGATION_LIST:
      return {
        ...state,
        adviceObligations: action.payload.adviceObligations,
      }
    case ADVICE_PACK_LIST:
      return {
        ...state,
        advicePacks: reduceAdvicePacksForSelection(action.payload.advicePacks),
      }
    case ADVICE_OBLIGATION_CREATE_CLEAN:
      return {
        ...state,
        adviceObligationCreateSuccess: null,
        adviceObligationCreateFailed: null,
      }
    case ADVICE_OBLIGATION_CREATE_FAILED:
      return {
        ...state,
        adviceObligationCreateFailed: true,
      }
    case ADVICE_OBLIGATION_CREATE_SUCCEED:
      return {
        ...state,
        adviceObligationCreateSuccess: true,
      }
    case ADVICE_OBLIGATION_OBJECT:
      return {
        ...state,
        adviceObligationObject: action.payload.adviceObligationObject,
      }
    case CLEAN_ADVICE_OBLIGATION_OBJECT:
      return {
        ...state,
        adviceObligationObject: null,
      }
    case TIME_WASTED_SESSIONS:
      return {
        ...state,
        obligationSessions: action.payload.sessions,
      }
    case TIME_WASTED_FINISHED:
      return {
        ...state,
        hoursWastedFinsished: true,
      }
    case CLEAN_TIME_WASTED_FINISHED:
      return {
        ...state,
        hoursWastedFinsished: null,
      }
    case ALL_TIME_WASTED_SESSIONS:
      return {
        ...state,
        sessions: action.payload.sessions,
      }
    case EXPORT_SUCCESS:
      return {
        ...state,
        exportSuccess: true,
      }
    case CLEAN_EXPORT_SUCCESS:
      return {
        ...state,
        exportSuccess: null,
      }
    case ADVICE_OBLIGATION_CANCELLATION_LIST:
      return {
        ...state,
        adviceObligationCancellations:
          action.payload.adviceObligationCancellations,
      }
    case ADVICE_OBLIGATION_CANCELLATION_SUCCEED:
      return {
        ...state,
        adviceObligationCancellationSucceed: true,
      }
    case ADVICE_OBLIGATION_CANCELLATION_FAILED:
      return {
        ...state,
        adviceObligationCancellationFailed: true,
      }
    case ADVICE_OBLIGATION_CANCELLATION_CLEAN:
      return {
        ...state,
        adviceObligationCancellationFailed: null,
        adviceObligationCancellationSucceed: null,
      }
    default:
      return state
  }
}

export const reduceAdvicePacksForSelection = (advicePacks) => {
  let advicePacksForSelection = []

  advicePacks.forEach((obj) => {
    advicePacksForSelection.push({
      value: obj.code,
      label: obj.code,
    })
  })

  return advicePacksForSelection
}

export default adviceReducer
