import React from "react"
import { Button } from "antd"

class FloatingButton extends React.Component {
  changeRole = () => {
    localStorage.removeItem("role")
    location.reload()
  }

  render() {
    return (
      <Button
        type="primary"
        style={styles.buttonStyle}
        onClick={this.changeRole}
      >
        Αλλαγή ρόλου
      </Button>
    )
  }
}

const styles = {
  buttonStyle: {
    position: "fixed",
    bottom: 16,
    left: 16,
    zIndex: 1000,
  },
}

export default FloatingButton
