import { showNotification } from "../../common/actions/notificationAction"
import { api } from "../../common/api/api"
import {
  MESSAGE_LIST,
  MESSAGE_SEND_CLEAN,
  MESSAGE_SEND_SUCCESS,
  MESSAGE_SEND_FAILED,
  CLEAN_MESSAGE_LIST,
  MESSAGE_HISTORY_LIST
} from "./types"

export const getMessagesByTaxNumber = (taxNumber) => (dispatch) => {
  api
    .get(`tenant/message/list/tax-number/${taxNumber}`)
    .then((response) => {
      dispatch({
        type: MESSAGE_LIST,
        payload: {
          messages: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getMessages = () => (dispatch) => {
  api
    .get(`tenant/message/list`)
    .then((response) => {
      dispatch({
        type: MESSAGE_LIST,
        payload: {
          messages: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const getMessageHistory = () => (dispatch) => {
  api
    .get(`tenant/message/history`)
    .then((response) => {
      dispatch({
        type: MESSAGE_HISTORY_LIST,
        payload: {
          messages: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const sendMessageByTaxNumber = (subject, body, files, taxNumber) => (dispatch) => {
  var formData = new FormData()
  formData.append("subject", subject)
  formData.append("body", body)
  formData.append("tax_number", taxNumber)
  files.forEach(file => {
    formData.append("files", file)
  })

  api
    .post("tenant/message/send/tax-number", formData)
    .then((response) => {
      dispatch({ type: MESSAGE_SEND_SUCCESS })
      dispatch(getMessagesByTaxNumber(taxNumber))
    })
    .catch((error) => {
      console.log(error)
      dispatch({ type: MESSAGE_SEND_FAILED })
      dispatch(showNotification("Αποτυχία αποστολής", "", "error"))
    })
}

export const sendMessageByAdvice = (form) => (dispatch) => {
  var formData = new FormData()
  formData.append("subject", form.subject)
  formData.append("body", form.body)
  form.advices.forEach(advice => {
    formData.append("advices", advice)
  })
  form.files.forEach(file => {
    formData.append("files", file)
  })

  api
    .post("tenant/message/send/advice", formData)
    .then((response) => {
      dispatch({ type: MESSAGE_SEND_SUCCESS })
      dispatch(getMessageHistory())
    })
    .catch((error) => {
      dispatch({ type: MESSAGE_SEND_FAILED })
      dispatch(showNotification("Αποτυχία αποστολής", error.data.detail, "error"))
    })
}


export const cleanSendMessage = () => (dispatch) => {
  dispatch({ type: MESSAGE_SEND_CLEAN })
}

export const cleanMessageList = () => (dispatch) => {
  dispatch({ type: CLEAN_MESSAGE_LIST })
}
