import React from "react"
import { Input, Button, Image } from "antd"
import {
  UserOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
  KeyOutlined,
} from "@ant-design/icons"

import { connect } from "react-redux"
import { onLogin, cleanAuth } from "../actions/authAction.js"
import {
  FARMER_LOGIN_BACKGROUND,
  LOGO_FILE,
  PLATFORM_NAMΕ,
} from "../../common/config.js"
import { addAlpha, primaryColor } from "../../common/colors"
import FloatingButton from "../../common/components/FloatingButton"

class FarmerLoginPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPasswordVisible: false,
      isLoading: false,

      username: "",
      password: "",
    }
  }

  componentDidUpdate() {
    if (this.props.error) {
      this.props.cleanAuth()
      this.setState({ isLoading: false })
    }
  }

  changePasswordVisibility = (value) => {
    this.setState({
      isPasswordVisible: value,
    })
  }

  onLoginClicked = () => {
    this.setState({ isLoading: true })
    this.props.onLogin(this.state.username, this.state.password)
  }

  render() {
    return (
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.2)), url(${FARMER_LOGIN_BACKGROUND})`,
          backgroundRepeat: "repeat",
          backgroundPosition: "top",
          backgroundSize: "cover",
          backgroundColor: "white",
          height: "120%",
        }}
      >
        <FloatingButton />
        <div style={styles.container}>
          <div style={{ textAlign: "center" }}>
            {/* Login Header */}
            {/* <Image
              preview={false}
              src={LOGO_FILE}
              width={240}
              style={{ marginBottom: "32px" }}
            /> */}
            <h2 style={{ color: primaryColor, fontWeight: "650" }}>
              {PLATFORM_NAMΕ}
            </h2>
            <br />
            <h3>Σύνδεση με τα στοιχεία άδειας χρήσης της πλατφόρμας</h3>
          </div>

          <Input
            style={styles.input}
            onChange={(e) => this.setState({ username: e.target.value })}
            value={this.state.username}
            size="large"
            placeholder="Όνομα χρήστη"
            prefix={<UserOutlined />}
          />
          <Input.Password
            prefix={<KeyOutlined />}
            style={styles.input}
            onChange={(e) => this.setState({ password: e.target.value })}
            value={this.state.password}
            placeholder="Κωδικός πρόσβασης"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
            visibilityToggle={{
              visible: this.state.isPasswordVisible,
              onVisibleChange: (visible) =>
                this.changePasswordVisibility(visible),
            }}
          />

          <Button
            style={styles.loginButton}
            onClickCapture={() => this.onLoginClicked()}
            type="primary"
            loading={this.state.isLoading}
          >
            Σύνδεση
          </Button>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    backgroundColor: addAlpha("#fffff0", 0.8),
    marginTop: "10%",
    marginRight: "25%",
    marginLeft: "25%",
    marginBottom: "30%",
    padding: "36px",
    boxShadow: "8px 19px 32px 15px rgba(200, 216, 243, 0.6)",
    borderRadius: 8,
  },
  loginButton: {
    marginTop: "2%",
  },
  input: {
    marginTop: "1%",
    height: "70px",
  },
}

const mapStateToProps = (state) => {
  return {
    error: state.farmerAuth.error,
  }
}

export default connect(mapStateToProps, { onLogin, cleanAuth })(FarmerLoginPage)
