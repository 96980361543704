import React from "react"
import { Col, Result, Row } from "antd"
import { NavLink } from "react-router-dom"

class NotFoundPage extends React.Component {
  renderTitle = () => {
    return <p>Η σελίδα δεν βρέθηκε...</p>
  }

  renderExtra = () => {
    return (
      <Row align={"center"} gutter={[40, 0]}>
        <Col span={24}>
          Πάμε σε κάποια άλλη σελίδα ;
          <br />
          <br />
        </Col>
        <Col span={24}>
          <NavLink to="/">Αρχική</NavLink>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <Row justify="center" align="center" className="full-viewport">
        <Col span={8} lg={8} xs={24} sm={24}>
          <Result
            status={404}
            title={this.renderTitle()}
            extra={[this.renderExtra()]}
          />
        </Col>
      </Row>
    )
  }
}

export default NotFoundPage
