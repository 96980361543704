import { Button, Card, Col, Descriptions, Modal, Row } from "antd"
import React from "react"
import { connect } from "react-redux"

import { Navigate } from "react-router-dom"

class AdiviceObligationModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      goToObligationPressed: false,
    }
  }

  renderGoToObligation = () => {
    if (!this.state.goToObligationPressed) {
      return <></>
    }

    return (
      <Navigate
        to={`/obligation/${this.props.adviceObligation.id}`}
        replace={true}
      />
    )
  }

  renderGoToObligationButton = () => {
    if (
      this.props.profile.tax_number !=
      this.props.adviceObligation.advisor.tax_number
    ) {
      return <></>
    }

    return (
      <Button onClick={() => this.setState({ goToObligationPressed: true })}>
        Δείτε την ανάθεση
      </Button>
    )
  }

  renderAssigner = () => {
    return (
      <Card type="inner" style={{ marginTop: "24px" }} title="Αναθέτης">
        <Descriptions
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Ονοματεπώνυμο",
              span: 24,
              children: `${this.props.adviceObligation.assigner.first_name} ${this.props.adviceObligation.assigner.last_name}`,
            },
            {
              key: "2",
              label: "Ηλεκτρονική Διεύθυνση ",
              span: 24,
              children: `${this.props.adviceObligation.assigner.email}`,
            },
          ]}
        />
      </Card>
    )
  }

  renderAdvisor = () => {
    return (
      <Card type="inner" style={{ marginTop: "24px" }} title="Σύμβουλος">
        <Descriptions
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Ονοματεπώνυμο",
              span: 24,
              children: `${this.props.adviceObligation.advisor.first_name} ${this.props.adviceObligation.advisor.last_name}`,
            },
            {
              key: "2",
              label: "Ηλεκτρονική Διεύθυνση ",
              span: 24,
              children: `${this.props.adviceObligation.advisor.email}`,
            },
          ]}
        />
      </Card>
    )
  }

  renderFarmer = () => {
    return (
      <Card
        type="inner"
        style={{ marginTop: "24px", height: "100%" }}
        title="Ωφελούμενος"
      >
        <Descriptions
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Ονοματεπώνυμο",
              span: 24,
              children: `${this.props.adviceObligation.farmer.first_name} ${this.props.adviceObligation.farmer.last_name}`,
            },
            {
              key: "2",
              label: "Α.Φ.Μ.",
              span: 24,
              children: `${this.props.adviceObligation.farmer.tax_number}`,
            },
            {
              key: "3",
              label: "Ηλεκτρονική Διεύθυνση ",
              span: 24,
              children: `${this.props.adviceObligation.farmer.email}`,
            },
          ]}
        />
      </Card>
    )
  }

  renderAdvicePack = () => {
    return (
      <Card
        type="inner"
        style={{ marginTop: "24px", height: "100%" }}
        title="Συμβουλή"
      >
        <Descriptions
          layout="vertical"
          items={[
            {
              key: "1",
              label: "Τίτλος",
              span: 24,
              children: `${this.props.adviceObligation.advice.code} ${this.props.adviceObligation.advice.title}`,
            },
            {
              key: "2",
              label: "Περιγραφή",
              span: 24,
              children: `${this.props.adviceObligation.advice.description}`,
            },
          ]}
        />
      </Card>
    )
  }

  render() {
    return (
      <Modal
        width={"70%"}
        footer={[
          <Button key="ok" type="primary" onClick={this.props.closeModal}>
            ΟΚ
          </Button>,
        ]}
        open={true}
        onCancel={() => this.props.closeModal()}
        onOk={() => this.props.closeModal()}
      >
        <>{this.renderGoToObligation()}</>
        <Card
          title={"Ανάθεση/υποχρέωση παροχής συμβουλών"}
          style={{ marginTop: "24px" }}
        >
          <>{this.renderGoToObligationButton()}</>
          <Row gutter={[10, 10]}>
            <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
              <div style={{ flex: 1, marginBottom: "16px" }}>
                {this.renderAdvicePack()}
              </div>
            </Col>
            <Col style={{ display: "flex", flexDirection: "column" }} span={12}>
              <div style={{ flex: 1, marginBottom: "16px" }}>
                {this.renderFarmer()}
              </div>
            </Col>
          </Row>

          <Row gutter={[10, 10]}>
            <Col span={12}>{this.renderAdvisor()}</Col>
            <Col span={12}>{this.renderAssigner()}</Col>
          </Row>
        </Card>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.tenantAuth.profile,
  }
}

export default connect(mapStateToProps, {})(AdiviceObligationModal)
