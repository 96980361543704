import { Button, Col, Divider, Row } from "antd"
import React from "react"
import AdviceObligationTable from "../components/AdviceObligationTable"
import RegisterFarmerModal from "../components/RegisterFarmerModal"
import CreateAdviceObligationModal from "../components/CreateAdviceObligationModal"
import { connect } from "react-redux"
import PageHeader from "../components/custom/PageHeader"
import { ADVICE_OBLIGATION_TEXT } from "../../common/texts"
import {
  PlusCircleOutlined, PlusOutlined
} from "@ant-design/icons"
import { primaryColor, secondaryColor } from "../../common/colors"

class AdviceObligation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isRegisterFarmerModalOpen: false,
      isAddObligationModalOpen: false,
    }
  }

  renderAddFarmer = () => {
    return (
      <>
        <Button
          style={{ marginTop: "16px" }}
          onClick={() => this.setState({ isRegisterFarmerModalOpen: true })}
          icon={<PlusOutlined style={{ color: secondaryColor }} />}
        >
          Προσθήκη ωφελούμενου
        </Button>
        {this.state.isRegisterFarmerModalOpen ? (
          <RegisterFarmerModal
            closeModal={() =>
              this.setState({ isRegisterFarmerModalOpen: false })
            }
          />
        ) : (
          <></>
        )}
      </>
    )
  }

  renderAddObligation = () => {
    return (
      <>
        <Button
          style={{ marginTop: "16px" }}
          onClick={() => this.setState({ isAddObligationModalOpen: true })}
          icon={<PlusOutlined style={{ color: secondaryColor }} />}
        >
          Προσθήκη ανάθεσης
        </Button>
        {this.state.isAddObligationModalOpen ? (
          <CreateAdviceObligationModal
            closeModal={() =>
              this.setState({ isAddObligationModalOpen: false })
            }
          />
        ) : (
          <></>
        )}
      </>
    )
  }

  render() {
    return (
      <>
        <PageHeader infoDescription={ADVICE_OBLIGATION_TEXT}>
          Αναθέσεις
        </PageHeader>

        <Row gutter={[20, 20]}>
          {this.props.profile.role == "tenant_admin" ? (
            <>
              <Col>{this.renderAddFarmer()}</Col>
              <Col>{this.renderAddObligation()}</Col>
            </>
          ) : (
            <></>
          )}

          <Col span={24}>
            <AdviceObligationTable />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    profile: state.tenantAuth.profile,
  }
}

export default connect(mapStateToProps, {})(AdviceObligation)
