import React from "react"
import TableWithLoader from "antd-table-loader"
import { Button, Skeleton, Tag } from "antd"
import { EditOutlined } from "@ant-design/icons"
import { connect } from "react-redux"
import { getTenantEmployees } from "../actions/tenantAction"
import EditEmployeeModel from "./EditEmployeeModel"

class TenantUserTable extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      userEditSelected: null
    }
  }

  componentDidMount() {
    this.props.getTenantEmployees()
  }

  columns = [
    {
      title: "Ονοματεπώνυμο",
      key: "full_name",
      align: "center",
      responsive: ["lg"],
      sorter: (a, b) => (a.code > b.code ? 1 : -1),
      render: (record) => `${record.first_name} ${record.last_name}`,
    },
    {
      title: "Όνομα χρήστη",
      dataIndex: "username",
      key: "username",
      align: "center",
      responsive: ["lg"],
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Πακέτα Συμβουλών",
      dataIndex: "advice_packs",
      render: (advice_packs) => {
        if (advice_packs.length == 0) {
          return <>--</>
        }
        return advice_packs.map((advice_pack) => {
          return <Tag>{advice_pack.code}</Tag>
        })
      },
      key: "advice_packs",
      align: "center",
    },
    {
      title: "",
      render: (record) => {
        return <>
          <Button
            onClick={() => this.setState({ userEditSelected: record })}
            icon={
              <EditOutlined />
            }>
          </Button>
        </>
      },
      key: "edit",
      align: "center",
    },
  ]

  onTenantUser = (record) => {
    console.log(record)
  }

  render() {
    return (
      <>
        {this.state.userEditSelected ?

          <EditEmployeeModel
            closeModal={() => this.setState({ userEditSelected: null })}
            employee={this.state.userEditSelected}
          />
          : <></>
        }

        <TableWithLoader
          className="table-global"
          size={"small"}
          bordered={true}
          pagination={true}
          loader={{
            rows: 4,
            component: <Skeleton active loading title={false} />,
          }}
          columns={this.columns}
          dataSource={this.props.tenantEmployees}
          rowKey={(record) => record.code}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => this.onTenantUser(record),
            }
          }}
        />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  console.log(state.tenant.tenantEmployees)
  return {
    tenantEmployees: state.tenant.tenantEmployees,
  }
}

export default connect(mapStateToProps, { getTenantEmployees })(TenantUserTable)
