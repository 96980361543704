import { combineReducers } from "redux"
import { LOGOUT } from "./common/actions/types"

import authReducer from "./tenant/reducers/authReducer"
import notificationReducer from "./common/reducers/notificationReducer"
import adviceReducer from "./tenant/reducers/adviceReducer"
import tenantReducer from "./tenant/reducers/tenantReducer"
import farmerReducer from "./tenant/reducers/farmerReducer"
import deliverableReducer from "./tenant/reducers/deliverableReducer"
import messageReducer from "./tenant/reducers/messageReducer"

// Farmer section
import farmerAuthReducer from "./farmer/reducers/authReducer"
import farmerMessageReducer from "./farmer/reducers/messageReducer"
import fieldReducer from "./farmer/reducers/fieldReducer"

const INIT_STATE = {}
const rootReducer = (state = INIT_STATE, action) => {
  if (action.type === LOGOUT) {
    state = undefined
  }

  return appReducer(state, action)
}

const appReducer = combineReducers({
  tenantAuth: authReducer,
  notifications: notificationReducer,
  advice: adviceReducer,
  tenant: tenantReducer,
  farmer: farmerReducer,
  deliverable: deliverableReducer,
  message: messageReducer,

  // Farmer section
  farmerAuth: farmerAuthReducer,
  farmerMessage: farmerMessageReducer,
  farmerField: fieldReducer,
})

export default rootReducer
