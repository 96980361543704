import { Col, Divider, Image, Popover, Row, Skeleton } from "antd"
import React from "react"
import { connect } from "react-redux"
import { getMessages, readMessage } from "../actions/messageAction"

import moment from "moment"
import TableWithLoader from "antd-table-loader"
import { tableRowCellWrapper } from "../../common/utils/utils"
import MessageDisplayModal from "../../common/components/MessageDisplayModal"
import { v4 } from "uuid"

class MessageScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      displayMessage: null,
    }
  }

  componentDidMount() {
    this.props.getMessages()
  }

  columns = [
    {
      title: "Από",
      align: "left",
      responsive: ["lg"],
      render: (record) =>
        tableRowCellWrapper(
          `${record.sender.first_name} ${record.sender.last_name}`,
          true,
          record.read_at == null
        ),
    },
    {
      title: "Θέμα",
      align: "left",
      sorter: (a, b) => (a.subject > b.subject ? 1 : -1),
      render: (record) =>
        tableRowCellWrapper(record.subject, true, record.read_at == null),
    },
    {
      title: "Ελήφθη",
      align: "left",
      responsive: ["lg"],
      sorter: (a, b) =>
        moment(a.sent_at).milliseconds > moment(b.sent_at).milliseconds
          ? 1
          : -1,
      render: (record) =>
        tableRowCellWrapper(
          moment(record.sent_at).fromNow(),
          true,
          record.read_at == null
        ),
    },
  ]

  tableSettings = {
    size: "small",
  }

  readMessage = (message) => {
    if (message.read_at == null) {
      this.props.readMessage(message.id)
    }
  }

  render() {
    return (
      <>
        {this.state.displayMessage ? (
          <MessageDisplayModal
            closeModal={() => this.setState({ displayMessage: null })}
            subject={this.state.displayMessage.subject}
            body={this.state.displayMessage.body}
            files={this.state.displayMessage.files}
          />
        ) : (
          <></>
        )}
        <Divider>Μηνύματα</Divider>
        <Row align={"middle"}>
          <Col span={24}>
            <TableWithLoader
              {...this.tableSettings}
              className="table-global"
              border={true}
              rowKey={(record) => Object.keys(record).length === 0 ? v4() : record.sent_at}
              rowClassName={(record) => "table-hover"}
              dataSource={this.props.messages}
              columns={this.columns}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    this.setState({ displayMessage: record })
                    this.readMessage(record)
                  },
                }
              }}
            />
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    messages: state.farmerMessage.messageList,
  }
}

const styles = {
  tableRowCell: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "200px",
  },
}

export default connect(mapStateToProps, {
  getMessages,
  readMessage,
})(MessageScreen)
