import {
  TENANT_REGISTER_SUCCESS,
  CLEAN_TENANT_REGISTER_SUCCESS,
  TENANT_EMPLOYEE_LIST,
} from "../actions/types"

const INIT_STATE = {}

const tenantReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case TENANT_REGISTER_SUCCESS:
      return {
        ...state,
        registerSucceed: true,
      }
    case TENANT_EMPLOYEE_LIST:
      return {
        ...state,
        tenantEmployees: action.payload.tenantEmployees,
      }
    case CLEAN_TENANT_REGISTER_SUCCESS:
      return {
        ...state,
        registerSucceed: false,
      }
    default:
      return state
  }
}

export default tenantReducer
