import { showNotification } from "../../common/actions/notificationAction"
import { LOGOUT } from "../../common/actions/types"
import { api, unauthorizedApi } from "../../common/api/api"
import {
  LOAD_AUTH,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  CLEAN_AUTH,
} from "./types"

export const onLogin = (username, password) => (dispatch) => {
  unauthorizedApi
    .post("farmer/login", { username, password })
    .then((response) => {
      const { token } = response.data

      localStorage.setItem("token", token)

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          token: token,
        },
      })
    })
    .catch((err) => {
      const { error } = err.response.data

      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          error: error,
        },
      })

      dispatch(showNotification("Αποτυχία σύνδεσης", error, "error"))
    })
}

export const getProfile = () => (dispatch) => {
  api
    .get("profile")
    .then((response) => {
      const profile = response.data
      localStorage.setItem("profile", JSON.stringify(profile))
      dispatch(loadAuth())
    })
    .catch((err) => { })
}

export const onLogout = () => (dispatch) => {
  localStorage.clear()
  window.location.replace(window.location.origin)

  dispatch({
    type: LOGOUT,
  })
}

export const loadAuth = () => (dispatch) => {
  const token = localStorage.getItem("token")

  const profile = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : {}

  dispatch({
    type: LOAD_AUTH,
    payload: {
      token: token,
      profile: profile,
    },
  })
}

export const cleanAuth = () => (dispatch) => {
  dispatch({
    type: CLEAN_AUTH,
  })
}
