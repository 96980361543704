import { showNotification } from "../../common/actions/notificationAction"
import { api } from "../../common/api/api"
import { DELIVERABLE_LIST, DELIVERABLE_FORM } from "./types"
import { saveAs } from "file-saver"

export const getDeliverables = () => (dispatch) => {
  api
    .get("printouts/deliverable/list")
    .then((response) => {
      dispatch({
        type: DELIVERABLE_LIST,
        payload: {
          deliverables: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

export const retrieveDeliverableForm =
  (code, advice, taxNumber) => (dispatch) => {
    api
      .get(
        `printouts/deliverable/${taxNumber}?deliverable=${code}&advice=${advice}`
      )
      .then((response) => {
        dispatch({
          type: DELIVERABLE_FORM,
          payload: {
            deliverableForm: response.data,
          },
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

const transformQuestionnaire = (questionnaire) => {
  if (!questionnaire) {
    return []
  }
  let finalQuestionnaire = []

  Object.entries(questionnaire).forEach(([key, value]) => {
    finalQuestionnaire.push({
      question_id: key,
      answer: value,
    })
  })

  return finalQuestionnaire
}

export const patchDeliverable = (taxNumber, form) => (dispatch) => {
  let formToSend = _.cloneDeep(form)
  formToSend.questionnaire = transformQuestionnaire(formToSend.questionnaire)
  api
    .patch(`printouts/deliverable/${taxNumber}`, { ...formToSend })
    .then((response) => {
      console.log(response)
      dispatch(retrieveDeliverableForm(form.deliverable_code, form.advice_code, taxNumber))
    })
    .catch((err) => {
      dispatch(showNotification("Αποτυχία", "", "error"))
      console.log(err)
    })
}

export const getDeliverablePrintout =
  (code, advice, taxNumber) => (dispatch) => {
    api
      .get(
        `printouts/deliverable/${taxNumber}/print?deliverable=${code}&advice=${advice}`,
        {
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        saveAs(
          new Blob([response.data], { type: "application/pdf" }),
          `παραδοτέο${code}-συμβουλή${advice}-${taxNumber}.pdf`
        )
        dispatch(
          showNotification("Επιτυχία", "Το παραδοτέο αποθηκέυτηκε.", "success")
        )
      })
      .catch((error) => {
        dispatch(
          showNotification(
            "Αποτυχία",
            "Πρόβλημα στην εκτύπωση του παραδοτέου.",
            "error"
          )
        )
      })
  }
