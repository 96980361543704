import React from "react"

class Cultivation extends React.Component {
  render() {
    return (
      <p style={styles.cultivarStyle}>
        {this.props.index}. {this.props.cultivation.cultivar}
      </p>
    )
  }
}

const styles = {
  cultivarStyle: {
    textAlign: "center",
  },
}

export default Cultivation
