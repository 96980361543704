import { Divider, Row } from "antd"
import React from "react"
import moment from "moment"
import { COMPANY_FULL_NAME, COMPANY_SITE } from "../../config"
import { secondaryColor } from "../../colors"

class CopyrightFooter extends React.Component {
  render() {
    return (
      <Row
        align={"middle"}
        justify={"center"}
        style={{ background: secondaryColor }}
      >
        <Divider />

        <div style={{ marginBottom: "8px", color: "white" }}>
          {moment().year()}{" "}
          <a target="_blank" rel="noreferrer" href={COMPANY_SITE}>
            {COMPANY_FULL_NAME}
          </a>
        </div>
      </Row>
    )
  }
}

export default CopyrightFooter
