// Authentication types
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"
export const LOGIN_FAILURE = "LOGIN_FAILURE"

export const LOAD_AUTH = "LOAD_AUTH"

export const CLEAN_AUTH = "CLEAN_AUTH"

// Advice
export const ADVICE_OBLIGATION_LIST = "ADVICE_OBLIGATION_LIST"
export const ADVICE_PACK_LIST = "ADVICE_PACK_LIST"
export const ADVICE_OBLIGATION_CREATE_CLEAN = "ADVICE_OBLIGATION_CREATE_CLEAN"
export const ADVICE_OBLIGATION_CREATE_FAILED = "ADVICE_OBLIGATION_CREATE_FAILED"
export const ADVICE_OBLIGATION_CREATE_SUCCEED =
  "ADVICE_OBLIGATION_CREATE_SUCCEED"
export const ADVICE_OBLIGATION_OBJECT = "ADVICE_OBLIGATION_OBJECT"
export const CLEAN_ADVICE_OBLIGATION_OBJECT = "CLEAN_ADVICE_OBLIGATION_OBJECT"

export const TIME_WASTED_FINISHED = "TIME_WASTED_FINISHED"
export const TIME_WASTED_SESSIONS = "TIME_WASTED_SESSIONS"
export const CLEAN_TIME_WASTED_FINISHED = "CLEAN_TIME_WASTED_FINISHED"
export const ALL_TIME_WASTED_SESSIONS = "ALL_TIME_WASTED_SESSIONS"
export const EXPORT_SUCCESS = "EXPORT_SUCCESS"
export const CLEAN_EXPORT_SUCCESS = "CLEAN_EXPORT_SUCCESS"

export const ADVICE_OBLIGATION_CANCELLATION_LIST =
  "ADVICE_OBLIGATION_CANCELLATION_LIST"
export const ADVICE_OBLIGATION_CANCELLATION_FAILED =
  "ADVICE_OBLIGATION_CANCELLATION_FAILED"
export const ADVICE_OBLIGATION_CANCELLATION_SUCCEED =
  "ADVICE_OBLIGATION_CANCELLATION_SUCCEED"
export const ADVICE_OBLIGATION_CANCELLATION_CLEAN =
  "ADVICE_OBLIGATION_CANCELLATION_CLEAN"
// Tenant
export const TENANT_REGISTER_SUCCESS = "TENANT_REGISTER_SUCCESS"
export const TENANT_EMPLOYEE_LIST = "TENANT_EMPLOYEE_LIST"

export const CLEAN_TENANT_REGISTER_SUCCESS = "CLEAN_TENANT_REGISTER_SUCCESS"

// Farmer
export const FARMER_REGISTER_SUCCESS = "FARMER_REGISTER_SUCCESS"
export const FARMER_REGISTER_CLEAN = "FARMER_REGISTER_CLEAN"
export const FARMER_REGISTER_FAILED = "FARMER_REGISTER_FAILED"
export const FARMER_LIST = "FARMER_LIST"
export const FARMER_FIELDS = "FARMER_FIELDS"
export const FARMER_LIVESTOCK = "FARMER_LIVESTOCK"

// Deliverable
export const DELIVERABLE_LIST = "DELIVERABLE_LIST"
export const DELIVERABLE_FORM = "DELIVERABLE_FORM"

// Message
export const MESSAGE_LIST = "MESSAGE_LIST"
export const MESSAGE_HISTORY_LIST = "MESSAGE_HISTORY_LIST"
export const MESSAGE_SEND_SUCCESS = "MESSAGE_SEND_SUCCESS"
export const MESSAGE_SEND_FAILED = "MESSAGE_SEND_FAILED"
export const MESSAGE_SEND_CLEAN = "MESSAGE_SEND_CLEAN"
export const CLEAN_MESSAGE_LIST = "CLEAN_MESSAGE_LIST"
