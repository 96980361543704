import { Modal } from "antd"
import React from "react"
import CultivationList from "./CultivationList"

class CultivationModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isModalOpen: false,
    }
  }

  componentDidUpdate() {
    if (this.props.isModalOpen !== this.state.isModalOpen) {
      this.setState({ isModalOpen: this.props.isModalOpen })
    }
  }

  render() {
    return (
      <Modal
        centered={true}
        footer={null}
        onCancel={this.props.onCloseModal}
        onOk={this.props.onCloseModal}
        open={this.state.isModalOpen}
      >
        <CultivationList cultivations={this.props.cultivations} />
      </Modal>
    )
  }
}

export default CultivationModal
