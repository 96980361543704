import React from "react"
import { connect } from "react-redux"
import { Button, Divider, Skeleton, Table } from "antd"
import TableWithLoader from "antd-table-loader"
import {
  getSessions,
  exportSessions,
  cleanExport,
} from "../../actions/adviceAction"
import {
  ClockCircleOutlined,
  CalendarOutlined,
  SmileOutlined,
  SolutionOutlined,
  AimOutlined,
  AuditOutlined,
  FileExcelOutlined
} from "@ant-design/icons"
import PageHeader from "../../components/custom/PageHeader"
import { ADVICE_TIME_WASTED_TEXT } from "../../../common/texts"
import { v4 } from "uuid"

class SessionsScreen extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingExport: false,
    }
  }

  componentDidMount() {
    this.props.getSessions()
  }

  componentDidUpdate() {
    if (this.props.exportSuccess) {
      this.props.cleanExport()
      this.setState({ loadingExport: false })
    }
  }

  sessionTableColumns = [
    {
      title: (
        <>
          <SmileOutlined style={{ marginRight: "16px" }} /> Ωφελούμενος
        </>
      ),
      children: [
        {
          title: 'Α.Φ.Μ.',
          align: 'center',
          render: (record) =>
            `${record.advice_obligation?.farmer.tax_number}`,
          key: 'session-screen-table-farmer-tax_number',
        },
        {
          title: 'Ονοματεπώνυμο',
          align: 'center',
          render: (record) =>
            `${record.advice_obligation?.farmer.first_name} ${record.advice_obligation?.farmer.last_name}`,
          key: 'session-screen-table-farmer-name',
        },
      ],
      key: "session-screen-table-farmer",
    },
    {
      title: (
        <>
          <SolutionOutlined style={{ marginRight: "16px" }} /> Συμβουλή
        </>
      ),
      render: (record) => record.advice_obligation?.advice?.code,
      key: "session-screen-table-advice",
    },
    {
      title: (
        <>
          <AimOutlined style={{ marginRight: "16px" }} /> Τύπος συνεδρίας
        </>
      ),
      dataIndex: "session_type",
      key: "session-screen-table-type",
    },
    {
      title: () => (
        <>
          <CalendarOutlined style={{ marginRight: "16px" }} /> Ημερομηνία
        </>
      ),
      dataIndex: "session_datetime",
      key: "session-screen-table-date",
    },
    {
      title: (
        <>
          <AuditOutlined style={{ marginRight: "16px" }} /> Παραδοτέο
        </>
      ),
      dataIndex: "deliverable_code",
      key: "session-screen-table-deliverable_code",
    },
    {
      title: (
        <>
          <ClockCircleOutlined style={{ marginRight: "16px" }} /> Ώρες
        </>
      ),
      dataIndex: "hours_wasted",
      key: "session-screen-table-hours",
    },
  ]

  renderSessions = () => {
    return (
      <TableWithLoader
        className="table-global"
        size={"small"}
        bordered={true}
        pagination={{ pageSize: 10 }}
        loader={{
          rows: 1,
          component: <Skeleton active loading title={false} />,
        }}
        rowKey={(record) => Object.keys(record).length === 0 ? v4() : record.id}
        columns={this.sessionTableColumns}
        dataSource={this.props.sessions}
      />
    )
  }

  onXlsExportClicked = () => {
    this.setState({ loadingExport: true })
    this.props.exportSessions()
  }

  renderExports = () => {
    return (
      <>
        <Button
          icon={<FileExcelOutlined />}
          loading={this.state.loadingExport}
          onClick={this.onXlsExportClicked}
        >
          Εξαγωγή σε excel
        </Button>
      </>
    )
  }

  render() {
    return (
      <>
        <PageHeader infoDescription={ADVICE_TIME_WASTED_TEXT}>
          Χρόνοι που αφιερώθηκαν σε συμβουλές
        </PageHeader>
        {this.renderExports()}
        {this.renderSessions()}
      </>
    )
  }
}

export const mapStateToProps = (state) => {
  return {
    sessions: state.advice.sessions,
    exportSuccess: state.advice.exportSuccess,
  }
}

export default connect(mapStateToProps, {
  getSessions,
  exportSessions,
  cleanExport,
})(SessionsScreen)
