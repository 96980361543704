import React from "react"
import { Button, Card, Col, Divider, Form, Input, Modal, Row, Upload } from "antd"
import { sendMessageByTaxNumber, cleanSendMessage } from "../actions/messageAction"
import { connect } from "react-redux"

import { UploadOutlined } from "@ant-design/icons"


class MessageSendModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      body: "",
      subject: "",
      files: [],

      loading: false,
    }
  }

  onSendClicked = () => {
    this.setState({ loading: true })
    this.props.sendMessageByTaxNumber(
      this.state.subject,
      this.state.body,
      this.state.files,
      this.props.taxNumber
    )
  }

  componentDidUpdate() {
    if (this.props.messageSentSuccessfully) {
      this.props.cleanSendMessage()
      this.setState({ loading: false })
      this.props.closeModal()
    }

    if (this.props.messageSentFailed) {
      this.setState({ loading: false })
      this.props.cleanSendMessage()
    }
  }

  renderMessageForm = () => {
    return (
      <>
        <Row style={{ marginTop: "1%" }} gutter={[20, 20]}>
          <Col span={16} >
            <Form layout="vertical">
              {/* Θέμα */}
              <Form.Item
                key={"name"}
                name="name"
                label={"Θέμα"}
                rules={[
                  {
                    required: true,
                    message: "Παρακαλώ εισάγετε θέμα.",
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    this.setState({ subject: e.target.value })
                  }}
                  className="inputs"
                  style={{ height: "8vh" }}
                  placeholder={"Θέμα"}
                  disabled={false}
                  maxLength={120}
                  showCount={true}
                />
              </Form.Item>

              {/* Μήνυμα */}
              <Form.Item
                key={"message"}
                type="message"
                name="message"
                value
                rules={[
                  {
                    required: true,
                    message: "Το κέιμενο δεν μπορεί να είναι κενό",
                  },
                ]}
              >
                <Input.TextArea
                  onChange={(e) => {
                    this.setState({ body: e.target.value })
                  }}
                  placeholder="Περιεχόμενο"
                  className="inputs"
                  style={{ height: "32vh", resize: "none" }}
                  maxLength={10000}
                  showCount={true}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={8}>
            <Card style={{ height: '50vh' }}>
              <Upload
                {...{
                  multiple: true,
                  onRemove: (file) => {
                    const index = this.state.files.indexOf(file);
                    const newFileList = this.state.files.slice();
                    newFileList.splice(index, 1);
                    this.setState({ files: newFileList })
                  },
                  beforeUpload: (file, fileList) => {
                    this.setState({ files: [...this.state.files, ...fileList] })
                    return false
                  },
                }}
              >
                <Button
                  style={{ marginTop: '8px', textAlign: 'center' }}
                  icon={<UploadOutlined />}>
                  Μεταφόρτωση αρχείων
                </Button>
              </Upload>
            </Card>
          </Col>
        </Row>
      </>
    )
  }

  render() {
    return (
      <Modal
        width={"70%"}
        height={"90%"}
        footer={[
          <Button key="ok" type="primary" onClick={this.props.closeModal}>
            Πίσω
          </Button>,
        ]}
        open={true}
        onCancel={() => this.props.closeModal()}
        onOk={() => this.props.closeModal()}
      >
        <Divider>Αποστολή μηνύματος</Divider>
        {this.renderMessageForm()}
        <Button
          loading={this.state.loading}
          onClick={() => this.onSendClicked()}
        >
          Αποστολή
        </Button>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    messageSentSuccessfully: state.message.sentSuccessfully,
    messageSentFailed: state.message.sentFailed,
  }
}

export default connect(mapStateToProps, { sendMessageByTaxNumber, cleanSendMessage })(
  MessageSendModal
)
