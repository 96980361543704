// Info texts
export const ADVICE_OBLIGATION_TEXT =
  "Εδώ βλέπετε τις υπάρχον αναθέσεις. Μπορείτε να δείτε ποιος έκανε την ανάθεση ποιος είναι ο σύμβουλος ποιος ο ωφελούμενος και ποια συμβουλή εφαρμόζεται."

export const ADVICE_CANCELLED_OBLIGATION_TEXT =
  "Εδώ βλέπετε τις υπάρχον ακυρωμένες αναθέσεις. Μπορείτε να δείτε ποιος έκανε την ανάθεση ποιος ήταν ο σύμβουλος ποιος ο ωφελούμενος ποια ήταν η συμβουλή καθώς και την αιτία της ακύρωσης."

export const ADVICE_TIME_WASTED_TEXT =
  'Εδώ βλέπετε το πότε και τους χρόνους που αφιέρωθηκαν για κάθε συμβουλή με τον συσχετιζόμενο ωφελούμενο. Επίσης μπορείτε να εξάγετε τον πίνακα σε μορφή excel πατώντας το κουμπί "Εξαγωγή σε excel".'

export const EMPLOYEE_TEXT =
  "Εδώ βλέπετε τους χρήστες της πλατφόρμας. Παρέχονται οι πληροφορίες σχετικά με τον χρήστη όπως το ονοματεπώνυμο το όνομα χρήστη για τη σύνδεση στην εφαρμογή και το email. Επίσης βλέπουμε ποιά πακέτα συμβουλών είναι διαθέσιμα για τον κάθε χρήστη"

export const CREATE_EMPLOYEE_TEXT =
  'Εδώ μπορείτε να δημιουργήσετε έναν χρήστη για τον φορέα σας. Είναι υποχρεωτικό να συμπληρώσετε τα πεδία όπως: Α.Φ.Μ., email, Όνομα χρήστη (για τη σύνδεση στην εφαρμογή), όνομα και επώνυμο, πατρώνυμο, το τηλέφωνο του χρήστη καθώς και το να επιλέξετε τον ρόλο που θα έχει. Σε περίπτωση που ο ρόλος είναι τύπου "Χειριστής φορέα" θα πρέπει να επιλεχθούν και τα πακέτα συμβουλών που θα έχει ο χρήστης. Σε περίπτωση που ο ρόλος είναι διαχειριστής φορέα δεν χρειάζεται να επιλεχθούν τα πακέτα συμβουλών.Ακόμα υπάρχει και το προαιρετικό πεδίο για τη συμπλήρωση του αριθμού ταυτότητας. Τέλος με το κουμπί "Δημιουργία χρήστη" δημιουργείτε τον χρήστη. Σε περίπτωση σφάλματος θα δείτε το αντίστοιχο μήνυμα στην οθόνη.'

export const DELIVERABLE_TEXT =
  'Εδώ βλέπετε τα παραδοτέα βάση της συμβουλής που είναι επιλεγμένη στην ανάθεση. Το κάθε παραδοτέο περιέχει το τίτλο τη περιγραφή του καθώς και τη περίοδο υποβολής. Πατώντας το κουμπί "Επεξεργασία" θα ανοίξει στην οθόνη σας ο διάλογος για να συμπληρώσετε πληρφορίες σχετικά με τη συμβουλή που δώσατε. Με το κουμπί "Εκτύπωση" θα κατεβάσατε το παραδοτέο σε μορφή pdf με τα συμπληρωμένα πεδία μετα την επεξεργασία.'

export const MESSAGE_TEXT =
  'Εδώ βλέπετε τα μηνύματα που έχετε στείλει στον ωφελούμενο για τη συγκεκριμένη συμβουλή. Πατώντας πάνω σε κάποιο μήνυμα θα σας ανοίξει ο διάλογος με ολόκληρο το μήνυμα που έχετε στείλει. Πατώντας το κουμπί "Αποστολή μηνύματος" θα σας ανοίξει στην οθόνη σας ο διάλογος για να γράψετε το θέμα και το περιεχόμενο του μηνυμάτος που θα αποσταλεί στον ωφελούμενος για τη συγκεκριμένη συμβουλή.'

export const SEND_MESSAGE_TEXT =
  'Εδώ στέλνετε μαζικά μηνύματα στους ωφελούμενους αναλόγως τα κριτήρια που έχετε επιλέξει.'

export const MESSAGE_HISTORY_TEXT =
  'Εδώ βλέπετε το ιστορικό των μαζικών μηνυμάτων που έχετε στείλει.'
