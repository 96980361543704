import {
  Button,
  Cascader,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  message,
} from "antd"
import React from "react"

import {
  createAdviceObligation,
  createAdviceObligationClean,
} from "../actions/adviceAction"
import { getTenantEmployees } from "../actions/tenantAction"
import { getFarmerList } from "../actions/farmerAction"
import { connect } from "react-redux"

class CreateAdviceObligation extends React.Component {
  formRef = React.createRef()

  constructor(props) {
    super(props)

    this.state = {
      obligationForm: {
        advice: null,
        advisor: null,
        farmer: null,
      },

      loading: false,
    }
  }

  componentDidMount() {
    this.props.getFarmerList()
    if (!this.props.tenantEmployees) {
      this.props.getTenantEmployees()
    }
  }

  createObligation = () => {
    this.setState({ loading: true })
    this.props.createAdviceObligation(this.state.obligationForm)
  }

  getTenantAdvices = () => {
    let tenant = this.props.tenantEmployees.find(
      (obj) => obj.id == this.state.obligationForm.advisor
    )

    return tenant.advices
  }

  updateFormItems = (formKey, value) => {
    this.setState((prevState) => ({
      obligationForm: {
        ...prevState.obligationForm,
        [formKey]: value,
      },
    }))
  }

  tenantEmployeesToRepresentation = () => {
    if (!this.props.tenantEmployees) return []
    let tenantsForSelection = []

    this.props.tenantEmployees.forEach((obj) => {
      tenantsForSelection.push({
        value: obj.id,
        label: `${obj.first_name} ${obj.last_name}`,
      })
    })

    return tenantsForSelection
  }

  farmerToRepresentation = () => {
    if (!this.props.farmers) return []
    let farmersForSelection = []

    this.props.farmers.forEach((obj) => {
      farmersForSelection.push({
        value: obj.id,
        label: `${obj.tax_number} | ${obj.first_name} ${obj.last_name}`,
      })
    })

    return farmersForSelection
  }

  adviceToRepresentation = () => {
    let advices = this.getTenantAdvices()

    let advicesForSelection = []

    advices.forEach((obj) => {
      advicesForSelection.push({
        value: obj.id,
        label: obj.code,
      })
    })

    return advicesForSelection
  }

  componentDidUpdate() {
    if (this.props.adviceObligationCreateSuccess) {
      this.props.createAdviceObligationClean()
      this.setState({ loading: false })
      this.props.closeModal()
    }

    if (this.props.adviceObligationCreateFailed) {
      this.props.createAdviceObligationClean()
      this.setState({ loading: false })
    }
  }

  renderForm = () => {
    return (
      <>
        <Row gutter={[2, 2]} style={{ marginTop: "16px" }}>
          <Col span={24}>
            <p style={{ marginRight: "8px" }}>Ωφελούμενος</p>
          </Col>
          <Col span={24}>
            <Cascader
              style={{ width: "30%" }}
              matchInputWidth
              showSearch={true}
              title="Ωφελούμενος"
              placement={"topLeft"}
              multiple={false}
              options={this.farmerToRepresentation()}
              onChange={(value) => {
                console.log(value)
                if (!value) {
                  this.updateFormItems("farmer", null)
                  return
                }
                this.updateFormItems("farmer", value[0])
              }}
            />
          </Col>
        </Row>

        <Row gutter={[2, 2]} style={{ marginTop: "16px" }}>
          <Col span={24}>
            <p style={{ marginRight: "8px" }}>Σύμβουλος</p>
          </Col>
          <Col span={24}>
            <Cascader
              style={{ width: "30%" }}
              matchInputWidth
              showSearch={true}
              title="Σύμβουλος"
              placement={"topLeft"}
              multiple={false}
              options={this.tenantEmployeesToRepresentation()}
              onChange={(value) => {
                console.log(value)
                if (!value) {
                  this.updateFormItems("advisor", null)
                  return
                }
                this.updateFormItems("advisor", value[0])
              }}
            />
          </Col>
        </Row>

        <Row gutter={[2, 2]} style={{ marginTop: "16px" }}>
          {this.state.obligationForm.advisor ? (
            <>
              <Col span={24}>
                <p style={{ marginRight: "8px" }}>Συμβουλές</p>
              </Col>
              <Col span={24}>
                <Cascader
                  style={{ width: "30%" }}
                  matchInputWidth
                  showSearch={true}
                  title="Συμβουλές"
                  placement={"topLeft"}
                  multiple={false}
                  allowClear={false}
                  options={this.adviceToRepresentation()}
                  onChange={(value) => {
                    if (!value) {
                      this.updateFormItems("advice", null)
                      return
                    }
                    this.updateFormItems("advice", value[0])
                  }}
                />
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>

        <Button
          onClick={this.createObligation}
          loading={this.state.loading}
          style={{
            marginTop: "32px",
          }}
        >
          Αναθέση συμβουλής
        </Button>
      </>
    )
  }

  render() {
    return (
      <Modal
        width={"70%"}
        footer={[
          <Button key="ok" type="primary" onClick={this.props.closeModal}>
            Άκυρο
          </Button>,
        ]}
        open={true}
        onCancel={() => this.props.closeModal()}
        onOk={() => this.props.closeModal()}
      >
        <Divider>Ανάθεση συμβουλής</Divider>
        <Row gutter={[10, 10]} style={{ marginTop: "36px" }}>
          <Col span={1} />
          <Col span={22}>{this.renderForm()}</Col>
          <Col span={1} />
        </Row>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    tenantEmployees: state.tenant.tenantEmployees,
    advices: state.advice.advices,
    farmers: state.farmer.farmerList,

    adviceObligationCreateSuccess: state.advice.adviceObligationCreateSuccess,
    adviceObligationCreateFailed: state.advice.adviceObligationCreateFailed,
  }
}

export default connect(mapStateToProps, {
  createAdviceObligation,
  createAdviceObligationClean,
  getTenantEmployees,
  getFarmerList,
})(CreateAdviceObligation)
