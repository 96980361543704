import { api } from "../../common/api/api"
import { FIELD_LIST } from "./types"

export const getFields = () => (dispatch) => {
  api
    .get(`farmer/fields`)
    .then((response) => {
      dispatch({
        type: FIELD_LIST,
        payload: {
          fields: response.data,
        },
      })
    })
    .catch((err) => {
      console.log(err)
    })
}
