import { Button, Divider, Input, Modal } from "antd"
import React from "react"
import { connect } from "react-redux"

import { registerFarmer, cleanFarmerRegister } from "../actions/farmerAction"

class RegisterFarmerModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = { taxNumber: null, loading: false }
  }

  onRegisterClicked = () => {
    this.setState({ loading: true })
    this.props.registerFarmer(this.state.taxNumber)
  }

  componentDidUpdate() {
    if (this.props.registerSucceed) {
      this.props.cleanFarmerRegister()
      this.setState({ loading: false })
      this.props.closeModal()
    }

    if (this.props.registerFailed) {
      this.props.cleanFarmerRegister()
      this.setState({ loading: false })
    }
  }

  renderForm = () => {
    return (
      <Input
        onChange={(e) => {
          this.setState({ taxNumber: e.target.value })
        }}
        className="inputs"
        style={{ height: "6vh" }}
        placeholder={"000000000"}
        disabled={false}
        maxLength={9}
        count={{
          max: 9,
          min: 9,
        }}
        showCount={false}
      />
    )
  }

  render() {
    return (
      <Modal
        width={"70%"}
        footer={[
          <Button key="ok" type="primary" onClick={this.props.closeModal}>
            Άκυρο
          </Button>,
        ]}
        open={true}
        onCancel={() => this.props.closeModal()}
        onOk={() => this.props.closeModal()}
      >
        <Divider>Καταχώρηση ωφελούμενου στο σύστημα</Divider>

        {this.renderForm()}

        <Button
          style={{ marginTop: "16px" }}
          loading={this.state.loading}
          onClick={this.onRegisterClicked}
        >
          Καταχώρηση
        </Button>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    registerSucceed: state.farmer.registerSucceed,
    registerFailed: state.farmer.registerFailed,
  }
}

export default connect(mapStateToProps, {
  registerFarmer,
  cleanFarmerRegister,
})(RegisterFarmerModal)
