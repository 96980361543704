import React from "react"
import { Card, Col, Row } from "antd"
import { primaryColor } from "./common/colors"
import {
  FARMER_LOGIN_BACKGROUND,
  TENANT_LOGIN_BACKGROUND,
} from "./common/config"

class LandPage extends React.Component {
  constructor(props) {
    super(props)
  }

  onFarmerClick = () => {
    localStorage.setItem("role", "farmer")
    location.reload()
  }

  onTenantClick = () => {
    localStorage.setItem("role", "tenant")
    location.reload()
  }

  render() {
    return (
      <div>
        <Row gutter={[16, 0]}>
          <Col span={12}>
            <div
              onClick={this.onTenantClick}
              className="image-container"
              style={{
                height: "100vh",
                background: `url(${TENANT_LOGIN_BACKGROUND}) center/cover no-repeat`,
              }}
            >
              <Card className="image-header" style={{ color: primaryColor }}>
                Φορέας
              </Card>
            </div>
          </Col>
          <Col span={12}>
            <div
              onClick={this.onFarmerClick}
              className="image-container"
              style={{
                height: "100vh",
                background: `url(${FARMER_LOGIN_BACKGROUND}) center/cover no-repeat`,
              }}
            >
              <Card className="image-header" style={{ color: primaryColor }}>
                Ωφελούμενος/Παραγωγός
              </Card>
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default LandPage
