import { showNotification } from "../../common/actions/notificationAction"
import { api } from "../../common/api/api"
import {
  FARMER_REGISTER_SUCCESS,
  FARMER_REGISTER_CLEAN,
  FARMER_REGISTER_FAILED,
  FARMER_LIST,
  FARMER_FIELDS,
  FARMER_LIVESTOCK,
} from "./types"

export const registerFarmer = (taxNumber) => (dispatch) => {
  api
    .post("farmer/register", { tax_number: taxNumber })
    .then((response) => {
      dispatch({
        type: FARMER_REGISTER_SUCCESS,
      })

      dispatch(
        showNotification(
          "Επιτυχία",
          `Ο ωφελούμενος με Α.Φ.Μ. ${taxNumber} καταχωρήθηκε επιτυχώς.`,
          "success"
        )
      )
    })
    .catch((err) => {
      const { detail } = err.response.data
      console.log(err.response)
      dispatch({
        type: FARMER_REGISTER_FAILED,
      })
      dispatch(showNotification("Αποτυχία καταχώρησης", detail, "error"))
    })
}

export const cleanFarmerRegister = () => (dispatch) => {
  dispatch({
    type: FARMER_REGISTER_CLEAN,
  })
}

export const getFarmerList = () => (dispatch) => {
  api
    .get("farmer/list")
    .then((response) => {
      dispatch({
        type: FARMER_LIST,
        payload: {
          farmerList: response.data,
        },
      })
    })
    .catch((error) => {
      console.log(err)
    })
}


export const getFarmerFieldList = (taxNumber) => (dispatch) => {
  api
    .get(`farmer/${taxNumber}/fields`)
    .then((response) => {
      dispatch({
        type: FARMER_FIELDS,
        payload: {
          fields: response.data,
        },
      })
    })
    .catch((error) => {
      console.log(err)
    })
}

export const getFarmerLivestockList = (taxNumber) => (dispatch) => {
  api
    .get(`farmer/${taxNumber}/livestock`)
    .then((response) => {
      dispatch({
        type: FARMER_LIVESTOCK,
        payload: {
          livestock: response.data,
        },
      })
    })
    .catch((error) => {
      console.log(err)
    })
}
