import { Col, Image, Menu, Row } from "antd"
import { Content } from "antd/es/layout/layout"

import React from "react"

import { connect } from "react-redux"
import { onLogout } from "../actions/authAction"


import {
  LogoutOutlined, MailOutlined, AimOutlined,
  UserOutlined,
} from "@ant-design/icons"
import { NavLink } from "react-router-dom"
import {
  lowerCaseAllWordsExceptFirstLetters,
  mapLocationToMenuKey,
} from "../../common/utils/utils"
import { primaryColor } from "../../common/colors"
import { COMPANY_FULL_NAME, COMPANY_SITE, LOGO_FILE, PLATFORM_NAMΕ } from "../../common/config"
import moment from "moment"

class M2Header extends React.Component {
  constructor(props) {
    super(props)
  }

  onLogoutPressed = () => {
    this.props.onLogout()
  }

  headerItems = () => {
    return [
      {
        key: "",
        label: <NavLink to="/">Συμβουλές</NavLink>,
        icon: <MailOutlined />,
      },
      {
        key: "fields",
        label: <NavLink to="/fields">Αγροτεμάχια</NavLink>,
        icon: <AimOutlined />,
      },
      {
        key: "logout",
        label: "Αποσύνδεση",
        danger: true,
        icon: <LogoutOutlined />,
        onClick: () => this.onLogoutPressed(),
      },
    ]
  }


  render() {
    return (
      <>
        <Row
          style={{
            top: 0,
            zIndex: 1000,
            width: "100%",
          }}
        >
          <Col
            span={4}
          >
            <Menu
              theme="dark"
              style={{
                borderRadius: 0,
                flexGrow: 1,
                minHeight: '100vh'
              }}
              selectedKeys={mapLocationToMenuKey()}
              mode="vertical"
            >

              {/* <Image
                style={{
                  alignSelf: "center",
                  marginBottom: 20,
                  marginTop: 20,
                  marginLeft: "10%",
                  //   ...LOGO_STYLES,
                }}
                width={190}
                src={LOGO_FILE}
                preview={false}
              /> */}

              <div style={{
                alignSelf: "center",
                marginBottom: 20,
                marginTop: 20,
                marginLeft: "10%",
                //   ...LOGO_STYLES,
              }}>

              </div>

              <Row
                style={{
                  marginLeft: "10%",
                }}
              >
                <Col span={2}>
                  <UserOutlined
                    style={{
                      marginTop: "45%",
                      fontSize: "22px",
                    }}
                  />
                </Col>
                <Col span={22}>
                  <Row>
                    <Col span={24}>
                      <p style={styles.userDetailsStyle}>
                        {lowerCaseAllWordsExceptFirstLetters(
                          `${this.props.profile.first_name} ${this.props.profile.last_name}`
                        )}
                      </p>
                    </Col>

                    <Col
                      span={24}
                      style={{
                        marginBottom: 20,
                        marginTop: "1.5%",
                        fontStyle: "italic",
                        marginLeft: "5%",
                      }}
                    >
                      <span>
                        Ωφελούμενος
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.headerItems().map((item) =>
                item.children ? (
                  <SubMenu key={item.key} icon={item.icon} title={item.label}>
                    {item.children.map((child) => (
                      <Menu.Item
                        icon={child.icon}
                        key={child.key}
                        onClick={child.onClick}
                        danger={item.danger}
                      >
                        {child.label}
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item
                    key={item.key}
                    icon={item.icon}
                    onClick={item.onClick}
                    danger={item.danger}
                  >
                    {item.label}
                  </Menu.Item>
                )
              )}
              <div style={{
                marginBottom: "8px",
                color: "white",
                position: 'absolute',
                bottom: 16, marginLeft: '10%',
              }}>
                {moment().year()}{" "}
                {/* <a target="_blank" rel="noreferrer" href={COMPANY_SITE}>
                  {COMPANY_FULL_NAME}
                </a> */}
              </div>
            </Menu>
          </Col>
          <Col span={20}>
            <Content style={{ margin: '2%' }}>{this.props.children}</Content>
          </Col>
        </Row>

      </>
    )
  }
}

const styles = {
  userDetailsStyle: {
    color: "white",
    marginLeft: "5%",
  },
  logoStyle: {
    color: primaryColor,
    marginLeft: "3%",
    marginTop: "1.5%",
  },
}

const mapStateToProps = (state) => {
  return {
    profile: state.farmerAuth.profile,
  }
}

export default connect(mapStateToProps, { onLogout })(M2Header)
